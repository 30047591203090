<div class="d-flex modal-body flex-column overflow-hidden h-100">
    <div class="flex-grow-1 d-flex flex-column overflow-hidden">
        <ng-container *ngIf="images">
            <app-images-select
                [class.disabled]="isUploading"
                (selectionChange)="selectedImages = $event"
                [options]="images"
            />
        </ng-container>
    </div>

    <div style="text-align: right" class="mt-3 pe-2 pb-2">
        <p-button
            label="取消"
            class="me-2"
            [text]="true"
            styleClass="p-button-text p-button-sm p-button-secondary"
            (onClick)="onCancel()"
        />
        <p-button
            label="上传美国平台"
            [text]="true"
            styleClass="p-button-text p-button-sm p-button-info"
            [disabled]="isUploading"
            [loading]="isUploading"
            (onClick)="onUpload()"
        />
    </div>
</div>
