import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentReturnRequestEditModel } from '../../core/model/treatmentReturnRequest';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
    createTreatmentReturnRequestEditForm,
    TreatmentReturnRequestEditComponent,
} from '../treatment-return-request-edit/treatment-return-request-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TreatmentReturnRequestClient } from '../../core/client/treatment-return-request-client.service';
import { RxHelper } from '../../core/utils/rxjxHelper';
import { firstValueFrom } from 'rxjs';
import { logger } from '../../core/utils/logger';
import { StyleClassModule } from 'primeng/styleclass';

export interface TreatmentReturnRequestEditDialogData {
    model: TreatmentReturnRequestEditModel;
    treatmentId: string;
}

@Component({
    selector: 'app-treatment-return-request-edit-dialog',
    standalone: true,
    imports: [CommonModule, TreatmentReturnRequestEditComponent, StyleClassModule, ReactiveFormsModule, ButtonModule],
    template: `
        <div class="d-flex modal-body flex-column overflow-hidden h-100">
            <div class="flex-grow-1 d-flex flex-column overflow-hidden">
                <app-treatment-return-request-edit class="flex-grow-1" [form]="form" />
            </div>
            <div style="text-align: right" class="mt-3">
                <p-button
                    label="取消"
                    [text]="true"
                    styleClass="p-button-text p-button-sm p-button-secondary"
                    (onClick)="onCancel()"
                />
                <p-button
                    label="确定"
                    (onClick)="onSave()"
                    [text]="true"
                    [loading]="isSaving"
                    styleClass="p-button-text p-button-sm p-button-info"
                    [disabled]="form.disabled || form.invalid || form.pristine"
                />
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                height: 100%;
                overflow: hidden;
            }
        `,
    ],
    providers: [RxHelper],
})
export class TreatmentReturnRequestEditDialog implements OnInit {
    private readonly _dialogConfig = inject<DynamicDialogConfig<TreatmentReturnRequestEditDialogData>>(
        DynamicDialogConfig,
        {
            optional: true,
        }
    );
    private readonly _dialogRef = inject(DynamicDialogRef, { optional: true });
    private readonly _apiClient = inject(TreatmentReturnRequestClient);

    protected readonly form = createTreatmentReturnRequestEditForm();
    protected isSaving = false;

    ngOnInit() {
        logger.logInformation('[treatment-return-request-dialog] opened');
        if (this._dialogConfig?.data?.model) this.form.patchValue(this._dialogConfig.data.model);
    }

    protected async onSave() {
        this.form.disable();
        this.isSaving = true;
        const rawValue = this.form.getRawValue();
        const treatmentId = this._dialogConfig?.data?.treatmentId;
        let hasSaved = false;
        if (rawValue && treatmentId)
            await firstValueFrom(this._apiClient.save(treatmentId, rawValue)).then(
                () => (hasSaved = true),
                reason => {
                    hasSaved = false;
                    logger.logError(
                        `Failed to save treatmentReturnRequest, treatment: ${treatmentId}, ${JSON.stringify(
                            rawValue
                        )}, ${JSON.stringify(reason)}`
                    );
                }
            );

        this.isSaving = false;
        this.form.enable();
        if (hasSaved) {
            logger.logInformation('[treatment-return-request-dialog] saved');
            this._dialogRef?.close(true);
        }
    }

    protected onCancel() {
        logger.logInformation('[treatment-return-request-dialog] onCancel');
        this._dialogRef?.close(false);
    }
}
