export const permissions = Object.freeze({
    review: 'Review',
    judgeDesignRevision: 'JudgeDesignRevision',
    grant: 'Grant',
    design: 'Design',
    listAllTreatments: 'ListAllTreatments',
    delivery: 'DeliverTreatmentFiles',
    editTreatmentNote: 'EditTreatmentNote',
    export: 'Export',
    markRedesign: 'MarkRedesign',
    uploadTreatmentReturnImagesToDesignService: 'UploadTreatmentReturnImagesToDesignService',
    replyTreatmentQuestion: 'ReplyTreatmentQuestion',
    updateTreatmentQuestionAssignee: 'UpdateTreatmentQuestionAssignee',
    answerTreatmentQuestion: 'AnswerTreatmentQuestion',
    assignDesigner: 'AssignDesigner',
});
