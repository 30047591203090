import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    Output,
    SimpleChange,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentDesign } from '../../core/model/treatment-design.model';
import { MemberClient } from '../../core/client/member-client.service';
import { Member } from '../../core/model/member.model';
import { MemberAvatarComponent } from '../member-avatar/member-avatar.component';
import { AssignReviewerComponent } from '../assign-reviewer/assign-reviewer.component';
import { take } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-treatment-reviewer',
    standalone: true,
    imports: [CommonModule, MemberAvatarComponent],
    template: `
        <ng-container>
            <div class="d-flex align-items-center" [class.disabled]="isDisable">
                <span *ngIf="reviewer" class="d-inline-flex align-items-center">
                    <app-member-avatar [member]="reviewer" />
                    &nbsp;{{ reviewer.fullName }}
                </span>
                <a *ngIf="showSelect" (click)="selectReviewer(design)" class="link ms-3" href="javascript:void 0">
                    选择
                </a>
            </div>
        </ng-container>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreatmentReviewerComponent implements OnChanges {
    @Input() design?: TreatmentDesign | null;
    @Input() reviewerId?: string | null;
    @Input() showSelect = true;
    @Input() isDisable = false;
    @Output() onReviewerSelected = new EventEmitter<Member>();

    protected reviewer?: Member | null;

    private readonly _memberClient = inject(MemberClient);
    private readonly _dialogService = inject(DialogService);
    private readonly _cdr = inject(ChangeDetectorRef);

    ngOnChanges(changes: Record<keyof TreatmentReviewerComponent, SimpleChange>): void {
        if (changes.design && this.design) {
            const { review } = this.design;
            if (review)
                this._memberClient.getById(review.reviewerId).subscribe(v => {
                    this.reviewer = v;
                    this.reviewerId = v.id;
                    this._cdr.markForCheck();
                });
        }
        if (changes.reviewerId && this.reviewerId) {
            this._memberClient.getById(this.reviewerId).subscribe(v => {
                this.reviewer = v;
                this._cdr.markForCheck();
            });
        }
    }

    selectReviewer(design?: TreatmentDesign | null): void {
        const ref = this._dialogService.open(AssignReviewerComponent, {
            width: '60vw',
            header: '选择质检员',
            data: {
                design,
                reviewerId: this.reviewerId,
            },
        });

        ref.onClose.pipe(take(1)).subscribe(r => {
            if (r && typeof r === 'object') {
                this.reviewer = r;
                this.reviewerId = r.id;
                this._cdr.markForCheck();
                this.onReviewerSelected.emit(r);
            }
        });
    }
}
