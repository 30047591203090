import { inject, Injectable } from '@angular/core';
import { ImageClient } from '../client/image-client';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class Base64ImageExtractService {
    private readonly _client = inject(ImageClient);

    async extract(
        html: string,
        {
            imagesFoundHook,
            imagesExtractedHook,
        }: {
            imagesFoundHook?: (event: { images: HTMLImageElement[] }) => unknown;
            imagesExtractedHook?: (event: { images: HTMLImageElement[]; value: string }) => unknown;
        } = {}
    ) {
        const divEl = document.createElement('div');
        divEl.innerHTML = html;
        const imageEls: HTMLImageElement[] = Array.from(divEl.querySelectorAll('img[src^="data:"]'));
        if (imageEls.length > 0) {
            imagesFoundHook?.({ images: imageEls });
        }
        await Promise.all(
            imageEls.map(el =>
                firstValueFrom(this._client.upload(this.dataURItoBlob(el.src))).then(url => (el.src = url))
            )
        );

        if (imageEls.length > 0) imagesExtractedHook?.({ images: imageEls, value: divEl.innerHTML });

        return divEl.innerHTML;
    }

    private dataURItoBlob(dataURI: string) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        //Old Code
        //write the ArrayBuffer to a blob, and you're done
        //var bb = new BlobBuilder();
        //bb.append(ab);
        //return bb.getBlob(mimeString);

        //New Code
        return new Blob([ab], { type: mimeString });
    }
}
