import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { VersionHistory } from '../../core/model/version-history.model';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { VersionHistoryClient } from '../../core/client/version-history-client.service';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { MarkdownModule } from 'ngx-markdown';

export interface VersionHistoryDialogData {
    versionHistories: VersionHistory[];
}

@Component({
    selector: 'app-version-history-dialog',
    standalone: true,
    imports: [CommonModule, DropdownModule, FormsModule, DatePipe, MarkdownModule],
    template: `
        <div>
            <div class="my-2 pb-2 py-1">
                <p-dropdown
                    *ngIf="histories"
                    [options]="histories"
                    [(ngModel)]="selectedVersionHistory"
                    (ngModelChange)="loadVersionContent()"
                    [showClear]="false"
                >
                    <ng-template pTemplate="selectedItem">
                        <ng-container
                            [ngTemplateOutlet]="versionHistoryTmpl"
                            [ngTemplateOutletContext]="{ $implicit: selectedVersionHistory }"
                        ></ng-container>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <ng-container
                            [ngTemplateOutlet]="versionHistoryTmpl"
                            [ngTemplateOutletContext]="{ $implicit: item }"
                        ></ng-container>
                    </ng-template>
                </p-dropdown>
            </div>

            <div class="content">
                <markdown
                    *ngIf="selectedVersionHistory && selectedVersionHistory.content"
                    [data]="selectedVersionHistory.content"
                ></markdown>
            </div>
        </div>

        <ng-template #versionHistoryTmpl let-versionHistory>
            <div class="flex align-items-center gap-2">
                <span>{{ versionHistory.version }}</span>
                <span class="ms-3">{{ versionHistory.timeStamp | date : 'yyyy/MM/dd HH:mm' }}</span>
            </div>
        </ng-template>
    `,
    styleUrls: ['./version-history-dialog.component.scss'],
    providers: [],
})
export class VersionHistoryDialogComponent implements OnInit, OnDestroy {
    private readonly _dialogConfig = inject<DynamicDialogConfig<VersionHistoryDialogData>>(
        DynamicDialogConfig<VersionHistoryDialogData>,
        { optional: true }
    );
    private readonly _versionHistoryClient = inject(VersionHistoryClient);
    readonly histories = this._dialogConfig?.data?.versionHistories;
    selectedVersionHistory?: VersionHistory | null = this.histories?.[0];
    private markReadTimeoutId?: number;

    ngOnInit() {
        this.loadVersionContent().then(() => {});
    }

    ngOnDestroy() {
        if (this.markReadTimeoutId) clearTimeout(this.markReadTimeoutId);
    }

    protected async loadVersionContent() {
        if (this.markReadTimeoutId) clearTimeout(this.markReadTimeoutId);

        if (this.selectedVersionHistory) {
            const version = this.selectedVersionHistory.version;
            this.selectedVersionHistory.content ??= await firstValueFrom(
                this._versionHistoryClient.getContent(this.selectedVersionHistory.version)
            );
            this.markReadTimeoutId = setTimeout(() => {
                clearTimeout(this.markReadTimeoutId);
                firstValueFrom(this._versionHistoryClient.markRead(version));
            }, 3000);
        }
    }
}
