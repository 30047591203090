import { Pipe, PipeTransform } from '@angular/core';
import { UserProfile } from '../model/user.profile.model';

@Pipe({
    name: 'hasAnyPermission',
    standalone: true,
})
export class HasAnyPermissionPipe implements PipeTransform {
    transform(u: Pick<UserProfile, 'permissions'> | null | undefined, permission: string, ...args: string[]): boolean {
        if (!u) return false;
        args.push(permission);
        const { permissions } = u;
        return permissions && permissions.length > 0 && permissions.some(p => args.includes(p));
    }
}
