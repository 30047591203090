import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageSelectOption } from './image-select-option';
import { SelectionModel } from '@angular/cdk/collections';
import { FormsModule } from '@angular/forms';
import { RxHelper } from '../../core/utils/rxjxHelper';

@Component({
    selector: 'app-images-select',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './images-select.component.html',
    styleUrls: ['./images-select.component.scss'],
    providers: [RxHelper],
})
export class ImagesSelectComponent<TOption extends ImageSelectOption> implements OnChanges, OnInit {
    @Input() options?: TOption[] | null = [];
    @Input() defaultSelectAll = true;

    @Output() readonly selectionChange = new EventEmitter<TOption[]>();

    readonly selections = new SelectionModel<TOption>(true);

    protected readonly _rxHelper = inject(RxHelper);

    ngOnInit(): void {
        this.selections.changed
            .pipe(this._rxHelper.autoUnsubscribe)
            .subscribe(() => this.selectionChange.emit(this.selections.selected));
    }

    ngOnChanges(changes: Record<keyof ImagesSelectComponent<ImageSelectOption>, SimpleChange>) {
        if (changes.options || changes.defaultSelectAll) {
            if (!this.options) this.selections.clear();
            else {
                const selectedImagesSrc = this.selections.selected.map(x => x.imageSrc);

                if (selectedImagesSrc.length > 0)
                    this.selections.select(...this.options.filter(opt => selectedImagesSrc.includes(opt.imageSrc)));
                else if (this.defaultSelectAll) {
                    this.selections.select(...this.options);
                }
                this.selectionChange.emit(this.selections.selected);
            }
        }
    }

    protected onSelectionChange(isSelected: boolean, item: TOption) {
        isSelected ? this.selections.select(item) : this.selections.deselect(item);
    }
}
