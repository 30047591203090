import { TreatmentQuestionReply } from './treatment-question-reply';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Members } from '../member.model';

export enum TreatmentQuestionStatus {
    none = 0,
    pendingAnswer = 1,
    answered = 2,
}

export interface TreatmentQuestion {
    id: number;
    assigneeId: string;
    content: string;
    dateAdded: Date | string;
    dateUpdated: Date | string;
    replies: TreatmentQuestionReply[];
    treatmentId: string;
    status: TreatmentQuestionStatus;
    authorId: string;
    assignees: Members;
}

export type TreatmentQuestions = TreatmentQuestion[];

export const createTreatmentQuestionEditForm = () =>
    new FormGroup({
        id: new FormControl({ disabled: true, value: 0 }),
        content: new FormControl({ disabled: false, value: '' }, [Validators.required]),
        assigneeId: new FormControl<string | undefined>({ disabled: false, value: undefined }),
        assignees: new FormControl<string[]>(
            { disabled: false, value: [] },
            {
                validators: [Validators.maxLength(10)],
                nonNullable: false,
            }
        ),
    });

export type TreatmentQuestionEditForm = ReturnType<typeof createTreatmentQuestionEditForm>;
export type TreatmentQuestionEditModel = TreatmentQuestionEditForm['value'];
