import { Observable, ReplaySubject, shareReplay, take } from 'rxjs';
import { TreatmentTypes } from '../model/treatmentType';
import { inject, Injectable } from '@angular/core';
import { TreatmentClient } from '../client/treatment-client';

@Injectable({
    providedIn: 'root',
})
export class TreatmentTypeStore {
    private readonly _source$ = new ReplaySubject<TreatmentTypes>(1);
    private _value$?: Observable<TreatmentTypes>;

    private readonly _client = inject(TreatmentClient);

    get value$() {
        return (this._value$ ??= this.refresh().pipe(shareReplay(1)));
    }

    refresh(): Observable<TreatmentTypes> {
        this._client
            .listTreatmentTypes()
            .pipe(take(1))
            .subscribe(types => this._source$.next(types));
        return this._source$;
    }
}
