import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { DateRange } from './model';

@Component({
    selector: 'app-date-range-select',
    standalone: true,
    imports: [CommonModule, CalendarModule, FormsModule],
    template: `
        <p-calendar
            class="max-w-full w-100"
            selectionMode="range"
            [ngModel]="rangeDates"
            (ngModelChange)="onValueChange($event)"
            [inline]="true"
            [numberOfMonths]="2"
            [showWeek]="true"
            [showTime]="true"
        ></p-calendar>
    `,
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class DateRangeSelectComponent {
    @Input() rangeDates: [Date, Date] = [new Date(), new Date()];
    @Output() rangeDatesChange = new EventEmitter<DateRange>();

    onValueChange(value: DateRange) {
        this.rangeDates = value;
        this.rangeDatesChange.emit(value);
    }
}
