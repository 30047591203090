export const ImageExtensions = {
    jpeg: '.jpeg',
    jpg: '.jpg',
    png: '.png',
    gif: '.gif',
    svg: '.svg',
    bmp: '.bmp',
};

export const VideoExtensions = {
    mp4: '.mp4',
    mov: '.mov',
};

export const FileExtensions = {
    stl: '.stl',
    ply: '.ply',
    spr: '.spr',

    pdf: '.pdf',
    docx: '.docx',
    txt: '.txt',

    dicom: '.dicom',
    zip: '.zip',

    ...ImageExtensions,
    ...VideoExtensions,
    folder: '.folder',
    json: '.json',
};
