import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, from, Observable, of, switchMap } from 'rxjs';
import {
    TreatmentReturnRequest,
    TreatmentReturnRequestEditModel,
    TreatmentReturnRequestReplies,
} from '../model/treatmentReturnRequest';
import { Base64ImageExtractService } from '../service/base64-image-extractor.service';
import { toFormData } from '../utils/formData';
import { logger } from '../utils/logger';

@Injectable({
    providedIn: 'root',
})
export class TreatmentReturnRequestClient {
    private readonly _httpClient = inject(HttpClient);
    private readonly _base64ImageExtractor = inject(Base64ImageExtractService);

    listByTreatmentId(treatmentId: string) {
        const path = `/api/treatments/${treatmentId}/return-requests`;
        return this._httpClient.get<TreatmentReturnRequest[]>(path).pipe(
            catchError(err => {
                logger.logWarning(`Failed to request ${path}, error:\n${JSON.stringify(err)}`);
                return of<TreatmentReturnRequest[]>([]);
            })
        );
    }

    listReplies(treatmentId: string, treatmentReturnRequestId: number): Observable<TreatmentReturnRequestReplies> {
        return this._httpClient
            .get<TreatmentReturnRequestReplies>(
                `/api/treatments/${treatmentId}/return-requests/${treatmentReturnRequestId}/replies`
            )
            .pipe(catchError(() => of([])));
    }

    save(treatmentId: string, value: TreatmentReturnRequestEditModel) {
        return from(this._base64ImageExtractor.extract(value.reason ?? '')).pipe(
            switchMap(reason =>
                this._httpClient.post(`/api/treatments/${treatmentId}/return-requests`, {
                    ...value,
                    reason,
                })
            )
        );
    }

    reply(treatmentId: string, value: FormData) {
        return this._httpClient.post(`/api/treatments/${treatmentId}/return-requests:reply`, value);
    }

    uploadImageToDesignService(treatmentId: string, treatmentReturnRequestId: number, imageSrc: string) {
        return this._httpClient.post<void>(
            `/api/treatments/${treatmentId}/return-requests/${treatmentReturnRequestId}:uploadImageToDesignService`,
            toFormData({ imageSrc })
        );
    }
}
