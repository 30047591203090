import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { GalleriaModule } from 'primeng/galleria';
import Viewer from 'viewerjs';
import { logger } from '../../core/utils/logger';

export interface ImageViewerItem {
    fileGuid: string;
    src: string;
    name: string | undefined;
}

export interface ImageViewerDialogData {
    startIndex: number;
    imageArr: ImageViewerItem[];
}

@Component({
    selector: 'app-image-viewer',
    standalone: true,
    imports: [CommonModule, GalleriaModule],
    template: `
        <div class="loading">
            <i *ngIf="loading" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
        <ul #images id="images" style="display: none;">
            <li><img *ngFor="let imageItem of _data.imageArr" [src]="imageItem.src" [alt]="imageItem.name" /></li>
        </ul>
    `,
    styleUrls: ['./image-viewer-dialog.component.scss'],
})
export class ImageViewerDialogComponent implements AfterViewInit, OnDestroy {
    @ViewChild('images') images?: ElementRef;
    public loading = true;
    private _dialogConfig = inject<DynamicDialogConfig<ImageViewerDialogData>>(
        DynamicDialogConfig<ImageViewerDialogData>,
        { optional: true }
    );
    readonly _data = this._dialogConfig?.data || { startIndex: 0, imageArr: [] };
    private gallery?: Viewer | null;
    private resizeObserver?: ResizeObserver | null;

    constructor(hostElementRef: ElementRef) {
        this.resizeObserver = new ResizeObserver(() => {
            (this.gallery as any)?.['resize']?.();
        });
        this.resizeObserver.observe(hostElementRef.nativeElement);
    }

    ngAfterViewInit(): void {
        this.gallery = new Viewer(this.images?.nativeElement, {
            inline: true,
            movable: false,
            rotatable: false,
            initialViewIndex: this._data.startIndex,
            fullscreen: false,
            button: false,
            keyboard: true,
            transition: false,
            minHeight: 400,
            minWidth: 600,
            toolbar: {
                zoomIn: 1,
                zoomOut: 1,
                oneToOne: 1,
                reset: 1,
                prev: 1,
                play: 0,
                next: 1,
                flipHorizontal: 1,
                flipVertical: 1,
            },
            ready: () => {
                logger.logInformation(`[ImageViewerDialog:Ready] data: ${JSON.stringify(this._data)}`);
                this.loading = false;
                this.gallery?.show();
            },
        });
    }

    ngOnDestroy(): void {
        this.resizeObserver?.disconnect();
    }
}
