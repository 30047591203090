<div class="cont-main">
    <div class="cont-checkbox" *ngFor="let iOption of options; index as i">
        <input
            type="checkbox"
            [id]="'myCheckbox-' + i"
            [ngModel]="selections.isSelected(iOption)"
            (ngModelChange)="onSelectionChange($event, iOption)"
        />
        <label [for]="'myCheckbox-' + i">
            <img [src]="iOption.imageSrc" />
            <span class="cover-checkbox">
                <svg viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </svg>
            </span>
            <div class="info"></div>
        </label>
    </div>
</div>
