import { Component, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembersSelectComponent } from '../../members-select';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MembersSelectDialogConfig } from './members-select-dialog-config';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Members } from '../../../core/model/member.model';
import { RxHelper } from '../../../core/utils/rxjxHelper';
import { ButtonModule } from 'primeng/button';
import { Role } from '../../../core/constants';

@Component({
    selector: 'app-members-select-dialog',
    standalone: true,
    imports: [CommonModule, MembersSelectComponent, ReactiveFormsModule, ButtonModule],
    template: `
        <div class="flex-grow-1 pt-1 overflow-hidden">
            <app-members-select
                [formControl]="formControl"
                [excludeUserIds]="excludeUserIds"
                [excludeRoles]="excludeRoles"
                [multi]="!disableMultiSelection"
            ></app-members-select>
        </div>

        <div>
            <div class="d-flex justify-content-end mt-4">
                <p-button
                    class="me-3"
                    styleClass="p-button-sm p-button-primary p-button-secondary p-button-outlined p-button-sm"
                    [outlined]="true"
                    label="取消"
                    (onClick)="onCancel()"
                />
                <p-button
                    [disabled]="formControl.pristine || formControl.invalid || formControl.disabled"
                    styleClass="p-button-sm p-button-primary p-button-outlined p-button-sm"
                    [outlined]="true"
                    label="保存"
                    (onClick)="onConfirm()"
                />
            </div>
        </div>
    `,
    styleUrls: ['./members-select-dialog.component.scss'],
    providers: [RxHelper],
})
export class MembersSelectDialogComponent {
    readonly formControl = new FormControl<Members>({
        disabled: false,
        value: [],
    });

    protected readonly excludeUserIds?: string[] | null;
    protected readonly excludeRoles?: Role[] | null;
    protected disableMultiSelection = false;

    constructor(
        private readonly _config: DynamicDialogConfig<MembersSelectDialogConfig>,
        @Optional() private readonly _dialogRef: DynamicDialogRef
    ) {
        this.excludeUserIds = this._config.data?.excludeUserIds;
        this.excludeRoles = this._config.data?.excludeRoles;
        this.disableMultiSelection = _config.data?.disableMultiSelection ?? false;

        const initialSelectMembers = _config.data?.members ?? [];
        if (initialSelectMembers.length > 0) {
            this.formControl.patchValue(initialSelectMembers);
        }
    }

    protected onConfirm() {
        this._dialogRef.close({ isSubmit: true, data: this.formControl.getRawValue() ?? [] });
    }

    protected onCancel() {
        this._dialogRef.close({ isSubmit: false });
    }
}
