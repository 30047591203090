import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerMetadata } from 'src/app/core/model/server-metadata.model';

@Injectable({ providedIn: 'root' })
export class ServerMetadataClient {
    constructor(private _http: HttpClient) {}

    get() {
        return this._http.get<ServerMetadata>('/api/server-metadata');
    }
}
