import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

@Injectable()
export class RxHelper implements OnDestroy {
    public $destroyed = new Subject<void>();

    public autoUnsubscribe = <T>(src: Observable<T>) => src.pipe(takeUntil(this.$destroyed));

    ngOnDestroy(): void {
        this.$destroyed.next();
        this.$destroyed.complete();
    }
}
