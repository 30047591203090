import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { logger } from '../../core/utils/logger';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImageSelectOption } from '../images-select/image-select-option';
import { ImagesSelectComponent } from '../images-select';
import { TreatmentReturnRequestClient } from '../../core/client/treatment-return-request-client.service';
import { firstValueFrom } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

export interface TreatmentReturnImageUploadDialogData {
    images: ImageSelectOption[];
    treatmentId: string;
    treatmentReturnRequestId: number;
}

const selector = 'app-treatment-return-images-upload-dialog';

@Component({
    selector: selector,
    standalone: true,
    imports: [CommonModule, ButtonModule, ImagesSelectComponent, ToastModule],
    templateUrl: './treatment-return-images-upload-dialog.component.html',
    styleUrls: ['./treatment-return-images-upload-dialog.component.scss'],
})
export class TreatmentReturnImagesUploadDialogComponent implements OnInit {
    protected images?: ImageSelectOption[] | null;
    protected selectedImages?: ImageSelectOption[] | null;
    protected isUploading = false;

    private readonly _dialogRef = inject(DynamicDialogRef, { optional: true });
    private readonly _dialogConfig = inject<DynamicDialogConfig<TreatmentReturnImageUploadDialogData>>(
        DynamicDialogConfig,
        {
            optional: true,
        }
    );
    private readonly _apiClient = inject(TreatmentReturnRequestClient);
    private readonly _toast = inject(MessageService);

    ngOnInit() {
        this.images = this._dialogConfig?.data?.images;
        logger.logInformation(`[${selector}] opened`);
    }

    protected async onUpload() {
        const { treatmentId, treatmentReturnRequestId } = this._dialogConfig?.data ?? {};
        if (!treatmentId || !treatmentReturnRequestId || this.selectedImages == null || this.selectedImages.length <= 0)
            return;

        this.isUploading = true;
        const failedArr: ImageSelectOption[] = [];
        for (const iSelectedImage of this.selectedImages ?? []) {
            const uploaded = await firstValueFrom(
                this._apiClient.uploadImageToDesignService(
                    treatmentId,
                    treatmentReturnRequestId,
                    iSelectedImage.imageSrc
                )
            ).then(
                () => true,
                reason => {
                    logger.logError(
                        `[${selector}] Failed to upload image to design service, treatment: ${treatmentId}, imageSrc: ${
                            iSelectedImage.imageSrc
                        }, ${JSON.stringify(reason)}`
                    );
                    return false;
                }
            );
            if (!uploaded) failedArr.push(iSelectedImage);
        }

        this._toast.add({
            severity: failedArr.length > 0 ? 'warn' : 'success',
            summary: '操作完成',
            detail: [
                failedArr.length < this.selectedImages.length
                    ? `${this.selectedImages.length} 张图片已上传美国平台, 请在附件中查看`
                    : '',
                failedArr.length > 0 ? `${failedArr.length} 张图片上传失败` : '',
            ]
                .filter(x => !!x)
                .join('\n'),
        });
        this.isUploading = false;
        this._dialogRef?.close(true);
    }

    protected onCancel() {
        logger.logInformation(`[${selector}] onCancel`);
        this._dialogRef?.close(false);
    }
}
