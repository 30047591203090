export const toFormData = <T extends { [K: string | number]: string | null | number | undefined }>(o: T) => {
    const f = new FormData();
    for (const k in o) {
        const v = o[k];
        if (v != null) {
            f.append(k, v.toString());
        }
    }
    return f;
};
