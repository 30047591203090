import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Member, Members } from '../model/member.model';
import { UserProfile } from '../model/user.profile.model';
import { Role, Roles } from '../model/role.model';
import { ListMembersParams } from './models';
import { PageModel } from '../model/page-model.model';
import { dropWhile, omitBy } from 'lodash-es';

const basePath = '/api/users';

export interface ListUsersParams {
    page?: number;
    size?: number;
    searchName?: string | null;
}

@Injectable({ providedIn: 'root' })
export class MemberClient {
    private _httpClient = inject(HttpClient);

    getById(userId: string) {
        return this._httpClient.get<Member>(`${basePath}/members/${userId}`);
    }

    getProfile() {
        return this._httpClient.get<UserProfile>(`${basePath}/profile`);
    }

    listRoles() {
        return this._httpClient.get<Roles>(`${basePath}/roles`);
    }

    listUsers({ page = 1, size = 20, searchName }: ListUsersParams = {}) {
        return this._httpClient.get<Members>(`${basePath}/admin/members`, {
            params: new HttpParams()
                .append('page', `${page}`)
                .append('pageSize', size)
                .append('searchName', searchName && searchName.length >= 3 ? searchName : ''),
        });
    }

    listReviewers({ searchName }: { searchName?: string | null }) {
        return this._httpClient.get<Members>(`${basePath}/reviewers`, {
            params: new HttpParams().append('searchName', searchName && searchName.length >= 3 ? searchName : ''),
        });
    }

    setRole(userId: string, roleId: number[]) {
        return this._httpClient.put<Members>(`${basePath}/${userId}/roles`, roleId);
    }

    listMembers(queryParams: ListMembersParams) {
        return this._httpClient.get<PageModel<Member>>(`${basePath}/members`, {
            params: new HttpParams({
                fromObject: { ...omitBy(queryParams, x => x == null) },
            }),
        });
    }
}
