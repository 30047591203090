import { enableProdMode, importProvidersFrom, SecurityContext } from '@angular/core';

import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'src/app/app.component';
import { provideRouter } from '@angular/router';
import { AppRoutes } from 'src/app/app.routing';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideAuthInterceptor } from './app/core/http-interceptor';
import { MarkdownModule } from 'ngx-markdown';
import { StyleClassModule } from 'primeng/styleclass';

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        {
            provide: 'BASE_URL',
            useFactory: getBaseUrl,
            deps: [],
        },
        provideRouter(AppRoutes),
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideAuthInterceptor(),
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: (http: HttpClient) => {
                        return new TranslateHttpLoader(http, './assets/i18n/', '.json');
                    },
                    deps: [HttpClient],
                },
                defaultLanguage: 'zh',
            }),
            MarkdownModule.forRoot({
                sanitize: SecurityContext.NONE,
            })
        ),
        StyleClassModule,
    ],
}).catch(console.error);
