import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

const basePath = '/api/files';

@Injectable({ providedIn: 'root' })
export class S3FileClient {
    private _client = inject(HttpClient);

    getDownloadLinks(fileGuid: string[]) {
        return this._client.get<Record<string, string>>(`${basePath}/download-links`, {
            params: fileGuid.reduce((p, c) => p.append('fileGuid', c), new HttpParams()),
        });
    }
}
