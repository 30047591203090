import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { EditorModule } from 'primeng/editor';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, first, map, of } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { TreatmentClient } from '../../core/client/treatment-client';
import { MessagesModule } from 'primeng/messages';
import { SafeContentPipe } from '../../core/pipe/safe-content.pipe';
import { RichTextComponent } from '../rich-text/rich-text.component';

@Component({
    selector: 'app-treatment-design-note-edit-dialog',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
        EditorModule,
        FormsModule,
        MessagesModule,
        SafeContentPipe,
        RichTextComponent,
    ],
    template: `
        <div class="d-flex flex-column h-100" *ngIf="designId">
            <div *ngIf="showMessage" class="mb-3">
                <p-messages severity="info">
                    <ng-template pTemplate>
                        <div class="d-flex gap-2 align-items-center">
                            <span *ngIf="messageIcon" class="pi" [ngClass]="messageIcon" style="font-size: 24px"></span>
                            <app-rich-text [text]="message ?? defaultMessage"></app-rich-text>
                        </div>
                    </ng-template>
                </p-messages>
            </div>
            <div class="flex-grow-1">
                <p-editor
                    [disabled]="isLoading"
                    [placeholder]="(isDesignNoteRequired ? '' : '选填, ') + '在此输入设计说明'"
                    [style]="{ height: '400px', width: '100%' }"
                    [(ngModel)]="designNote"
                />
            </div>

            <div class="d-flex justify-content-end mt-4">
                <p-button
                    class="me-3"
                    styleClass="p-button-sm p-button-primary p-button-secondary p-button-outlined"
                    [outlined]="true"
                    label="取消"
                    [disabled]="isSubmitting || (!!isDesignNoteRequired && !this.designNote)"
                    (onClick)="onCancel()"
                />
                <p-button
                    [loading]="isSubmitting"
                    [disabled]="isSubmitting || (!!isDesignNoteRequired && !this.designNote)"
                    styleClass="p-button-sm p-button-primary p-button-outlined"
                    [outlined]="true"
                    [label]="submitButtonText ?? defaultSubmitButtonText"
                    (onClick)="onConfirm()"
                />
            </div>
        </div>
    `,
    styleUrls: ['./treatment-design-note-edit-dialog.component.scss'],
})
export class TreatmentDesignNoteEditDialogComponent {
    @Input() submitButtonText?: string | null;
    @Input() message?: string | null;
    @Input() messageIcon?: string | null;
    @Input() isDesignNoteRequired?: boolean | null = false;
    @Input() showMessage = true;

    treatmentId?: string | null;
    designNote = '';
    isSubmitting = false;
    isLoading = false;
    designId?: number | null;

    protected readonly defaultSubmitButtonText = '提交质检';
    protected readonly defaultMessage = '返修时, 提交设计文件需要提供设计说明';

    protected readonly ref = inject(DynamicDialogRef, { optional: true });
    private readonly _config = inject(DynamicDialogConfig, { optional: true });
    private readonly _treatmentClient = inject(TreatmentClient);

    constructor() {
        this.treatmentId = this._config?.data.treatmentId;
        this.designId = this._config?.data.designId;
        this.designNote = this._config?.data.designNote ?? '';
        this.message = this._config?.data.message;
        this.submitButtonText = this._config?.data.submitButtonText;
        this.messageIcon = this._config?.data.messageIcon;
        this.isDesignNoteRequired = this._config?.data.isDesignNoteRequired;
        this.showMessage = this._config?.data.showMessage ?? this.showMessage;
    }

    protected onConfirm() {
        if (this.treatmentId && this.designId && (!this.isDesignNoteRequired || this.designNote)) {
            this.isSubmitting = true;
            this._treatmentClient
                .putDesignNote(this.treatmentId, this.designId, this.designNote)
                .pipe(
                    catchError(() => of(false)),
                    map(() => true),
                    first()
                )
                .subscribe(r => {
                    this.isSubmitting = false;
                    if (r)
                        this.ref?.close({
                            confirmed: true,
                            data: this.designNote,
                        });
                });
        }
    }

    protected onCancel() {
        this.ref?.close(false);
    }
}
