import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserStore } from 'src/app/core/store/user.store';
import { AvatarModule } from 'primeng/avatar';
import { first, firstValueFrom, map, merge, Observable, ReplaySubject, shareReplay } from 'rxjs';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/core/service/auth.service';
import { UserProfile } from '../../core/model/user.profile.model';
import { MemberAvatarComponent } from '../member-avatar/member-avatar.component';
import { HasAnyPermissionPipe } from '../../core/pipe/has-any-permission.pipe';
import { permissions } from '../../core/constants/permissions';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { BadgeModule } from 'primeng/badge';
import { DialogService } from 'primeng/dynamicdialog';
import { VersionHistoryDialogComponent, VersionHistoryDialogData } from '../dialogs';
import { VersionHistoryClient } from '../../core/client/version-history-client.service';
import { DialogModule } from 'primeng/dialog';
import { VersionHistory } from '../../core/model/version-history.model';
import { TranslateModule } from '@ngx-translate/core';

const mapRole = (roles: string[]): string | null | undefined => {
    const dict: Record<string, string> = {
        reviewer: '质检员',
        admin: '公司管理员',
        labadmin: '工作室管理员',
        designer: '设计师',
    };
    roles = roles.map(r => r.toLowerCase());
    for (const k in dict) {
        if (roles.some(x => x === k)) return dict[k];
    }

    return null;
};

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        AvatarModule,
        MenuModule,
        MemberAvatarComponent,
        HasAnyPermissionPipe,
        TagModule,
        ChipModule,
        BadgeModule,
        DialogModule,
        TranslateModule,
    ],
    providers: [DialogService],
})
export class NavMenuComponent {
    isExpanded = false;
    readonly currentUser$ = this._userStore.currentUser$;
    readonly avatarItems$: Observable<MenuItem[]> = this.currentUser$.pipe(
        map<UserProfile, MenuItem[]>(({ fullName, email }) => {
            return [
                {
                    label: `<span>${fullName}</span><br/><span class="mt-3 d-inline-flex" style="word-break: break-all">${email}</span>`,
                    disabled: true,
                    escape: false,
                    style: {},
                },
                {
                    separator: true,
                },
                {
                    label: '退出',
                    command: () => this._authService.logout(),
                },
            ];
        })
    );
    readonly mainRole$ = this.currentUser$.pipe(
        map(({ mainRole }) => 'roles.' + mainRole),
        shareReplay(1)
    );
    versionHistories$ = new ReplaySubject<VersionHistory[]>(1);
    readonly latestVersion$ = this.versionHistories$.pipe(
        map(x => x[0]?.version),
        shareReplay(1)
    );
    readonly isVersionUnread$ = this.versionHistories$.pipe(
        map(x => x[0]?.isUnread ?? false),
        shareReplay(1)
    );

    constructor(
        private readonly _userStore: UserStore,
        private readonly _authService: AuthService,
        private readonly _dialogService: DialogService,
        private readonly _versionHistoryClient: VersionHistoryClient
    ) {
        this._versionHistoryClient
            .listVersionHistories()
            .pipe(first())
            .subscribe(v => this.versionHistories$.next(v));
    }

    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }

    async openVersionHistoryDialog() {
        const ref = this._dialogService.open(VersionHistoryDialogComponent, {
            data: {
                versionHistories: await firstValueFrom(this.versionHistories$),
            } as VersionHistoryDialogData,
            maximizable: false,
            styleClass: 'version-histories-dialog',
            resizable: false,
            draggable: false,
            modal: true,
            header: '版本历史',
            // style: {
            //     width: '85vw',
            // },
        });

        merge(ref.onClose, ref.onDestroy)
            .pipe(first())
            .subscribe(() => {
                this._versionHistoryClient
                    .listVersionHistories()
                    .pipe(first())
                    .subscribe(v => this.versionHistories$.next(v));
            });
    }

    protected readonly permissions = permissions;
}
