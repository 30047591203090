import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Treatment } from '../../core/model/treatment';
import { EditorModule } from 'primeng/editor';
import { FormsModule } from '@angular/forms';
import { TreatmentClient } from '../../core/client/treatment-client';
import { catchError, of, take } from 'rxjs';

@Component({
    selector: 'app-redesign-requirement-dialog',
    standalone: true,
    imports: [CommonModule, ButtonModule, EditorModule, FormsModule],
    template: `
        <div class="d-flex flex-column h-100" *ngIf="treatment">
            <div class="flex-grow-1">
                <p-editor
                    [disabled]="isLoadingRequirement"
                    placeholder="在此输入改进需求, 可以不填"
                    [style]="{ height: '400px', width: '100%' }"
                    [(ngModel)]="requirement"
                />
            </div>

            <div class="d-flex justify-content-end mt-4">
                <p-button
                    class="me-3"
                    styleClass="p-button-sm p-button-primary p-button-secondary p-button-outlined"
                    [outlined]="true"
                    label="取消"
                    [disabled]="isSubmitting"
                    (onClick)="onCancel()"
                />
                <p-button
                    [loading]="isSubmitting"
                    [disabled]="isSubmitting"
                    styleClass="p-button-sm p-button-primary p-button-outlined"
                    [outlined]="true"
                    label="确定"
                    (onClick)="onConfirm()"
                />
            </div>
        </div>
    `,
    styleUrls: ['./redesign-requirement-dialog.component.scss'],
})
export class RedesignRequirementDialogComponent {
    treatment?: Treatment | null;
    requirement?: string | null;
    isSubmitting = false;
    isLoadingRequirement = false;

    protected readonly ref = inject(DynamicDialogRef, { optional: true });
    private readonly _config = inject(DynamicDialogConfig, { optional: true });
    private readonly _treatmentClient = inject(TreatmentClient);

    constructor() {
        this.treatment = this._config?.data.treatment;
        if (this.treatment) {
            this.isLoadingRequirement = true;
            this._treatmentClient
                .getRedesignRequirement(this.treatment.id)
                .pipe(take(1))
                .subscribe({
                    next: x => {
                        this.isLoadingRequirement = false;
                        this.requirement = x;
                    },
                    error: () => {
                        this.isLoadingRequirement = false;
                    },
                });
        }
    }

    protected onConfirm() {
        const { treatment } = this;
        if (treatment) {
            this.isSubmitting = true;
            this._treatmentClient
                .markRedesign(treatment.id, this.requirement)
                .pipe(
                    catchError(() => of(false)),
                    take(1)
                )
                .subscribe(r => {
                    this.isSubmitting = false;

                    this.ref?.close(r !== false);
                });
        }
    }

    protected onCancel() {
        this.ref?.close(false);
    }
}
