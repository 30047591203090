import { TreatmentTypeOriginalId } from './treatmentType';
import { TreatmentFileTypeEnum } from './treatment-file.model';

export const ModelColors = [
    { treatmentType: TreatmentTypeOriginalId.NightGuard, modelColor: '#777DC4', crossColor: '#303BBA' },
    {
        treatmentType: TreatmentTypeOriginalId.SmileDesign,
        modelColor: '#ab9184',
        crossColor: '#ab6543',
        treatmentFiles: [
            { fileType: TreatmentFileTypeEnum.TrialSmileUpperStl, modelColor: '#B0B0B0', crossColor: '#626262' },
            { fileType: TreatmentFileTypeEnum.TrialSmileLowerStl, modelColor: '#B0B0B0', crossColor: '#626262' },
            { fileType: TreatmentFileTypeEnum.WaxUpUpperStl, modelColor: '#C59C62', crossColor: '#C4872F' },
            { fileType: TreatmentFileTypeEnum.WaxUpLowerStl, modelColor: '#C59C62', crossColor: '#C4872F' },
        ],
    },
    { treatmentType: TreatmentTypeOriginalId.BracketRemoval, modelColor: '#C59C62', crossColor: '#C4872F' },
    { treatmentType: TreatmentTypeOriginalId.SurgicalGuide, modelColor: '#BBAE91', crossColor: '#BA9747' },
    { treatmentType: TreatmentTypeOriginalId.RemovablePartialDenture, modelColor: '#C59C62', crossColor: '#C4872F' },
    { treatmentType: TreatmentTypeOriginalId.ClearAligner, modelColor: '#C59C62', crossColor: '#C4872F' },
    { treatmentType: TreatmentTypeOriginalId.SingleImplantWithCrown, modelColor: '#B0B0B0', crossColor: '#626262' },
    {
        treatmentType: TreatmentTypeOriginalId.CrownAndBridge,
        modelColor: '#B0B0B0',
        crossColor: '#626262',
        treatmentFiles: [
            { fileType: TreatmentFileTypeEnum.RemovableDieStl, modelColor: '#C59C62', crossColor: '#C4872F' },
            { fileType: TreatmentFileTypeEnum.TemporaryCrownStl, modelColor: '#B0B0B0', crossColor: '#626262' },
            { fileType: TreatmentFileTypeEnum.UpperJaw, modelColor: '#C59C62', crossColor: '#C4872F' },
            { fileType: TreatmentFileTypeEnum.LowerJaw, modelColor: '#C59C62', crossColor: '#C4872F' },
        ],
    },
    { treatmentType: TreatmentTypeOriginalId.CustomBleachingTray, modelColor: '#C59C62', crossColor: '#C4872F' },
    {
        treatmentType: TreatmentTypeOriginalId.RemovableDentures,
        modelColor: '#C59C62',
        crossColor: '#C4872F',
        treatmentFiles: [
            { fileType: TreatmentFileTypeEnum.DentureTeethStl, modelColor: '#DDDDDD', crossColor: '#999999' },
            { fileType: TreatmentFileTypeEnum.DentureGumBaseStl, modelColor: '#F46464', crossColor: '#F52F2F' },
            {
                fileType: TreatmentFileTypeEnum.MonolithicTryInDentureStl,
                modelColor: '#DDDDDD',
                crossColor: '#999999',
            },
        ],
    },
    { treatmentType: TreatmentTypeOriginalId.AINightGuard, modelColor: '#777DC4', crossColor: '#303BBA' },
    { treatmentType: TreatmentTypeOriginalId.HybridDentures, modelColor: '#DDDDDD', crossColor: '#999999' },
    {
        treatmentType: TreatmentTypeOriginalId.AICrown,
        modelColor: '#B0B0B0',
        crossColor: '#626262',
        treatmentFiles: [
            { fileType: TreatmentFileTypeEnum.AICrownDieStl, modelColor: '#C59C62', crossColor: '#C4872F' },
        ],
    },
    {
        treatmentType: TreatmentTypeOriginalId.Veneers,
        modelColor: '#ab9184',
        crossColor: '#ab6543',
        treatmentFiles: [
            { fileType: TreatmentFileTypeEnum.VeneersStl, modelColor: '#B0B0B0', crossColor: '#626262' },
            { fileType: TreatmentFileTypeEnum.UpperJaw, modelColor: '#ab9184', crossColor: '#ab6543' },
            { fileType: TreatmentFileTypeEnum.LowerJaw, modelColor: '#ab9184', crossColor: '#ab6543' },
        ],
    },
    { treatmentType: TreatmentTypeOriginalId.Custom, modelColor: '#C59C62', crossColor: '#C4872F' },
];
