import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TreatmentQuestionEditModel, TreatmentQuestionReplyEditModel, TreatmentQuestions } from '../model';

const basePath = '/api';

@Injectable({ providedIn: 'root' })
export class TreatmentQuestionClient {
    private _http = inject(HttpClient);

    listQuestions(treatmentId: string) {
        return this._http.get<TreatmentQuestions>(`${basePath}/treatments/${treatmentId}/questions`);
    }

    askQuestion(treatmentId: string, model: TreatmentQuestionEditModel) {
        return this._http.post(`${basePath}/treatments/${treatmentId}/questions:ask`, model);
    }

    replyQuestion(treatmentId: string, questionId: number, model: TreatmentQuestionReplyEditModel) {
        return this._http.post(`${basePath}/treatments/${treatmentId}/questions/${questionId}:reply`, model);
    }

    updateAssignees(treatmentId: string, questionId: number, assignees: string[]) {
        return this._http.put<void>(`${basePath}/treatments/${treatmentId}/questions/${questionId}/assignees`, {
            questionId,
            assignees,
        });
    }
}
