import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

export namespace logger {
    export enum LogLevel {
        verbose,
        debug,
        information,
        warning,
        error,
        fatal,
    }

    const _localLog = {
        [LogLevel.verbose]: console.debug,
        [LogLevel.debug]: console.debug,
        [LogLevel.information]: console.info,
        [LogLevel.warning]: console.warn,
        [LogLevel.error]: console.error,
        [LogLevel.fatal]: console.error,
    } as const;

    let _client: HttpClient;

    export function init(client: HttpClient) {
        _client = client;
    }

    export function log(message: string, level: LogLevel) {
        try {
            _localLog[level]?.(message);
        } catch (err) {
            console.error(err);
        }

        message = `[${window.location.pathname}${window.location.search}] ${message}`;
        try {
            firstValueFrom(_client?.post(`/api/telemetry/logs/${level}`, { body: message }));
        } catch (err) {
            console.error(err);
        }
    }

    export function logVerbose(message: string) {
        log(message, LogLevel.verbose);
    }

    export function logDebug(message: string) {
        log(message, LogLevel.debug);
    }

    export function logInformation(message: string) {
        log(message, LogLevel.information);
    }

    export function logWarning(message: string) {
        log(message, LogLevel.warning);
    }

    export function logError(message: string) {
        log(message, LogLevel.error);
    }

    export function logFatal(message: string) {
        log(message, LogLevel.fatal);
    }
}
