import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const basePath = '/api/images';

@Injectable({ providedIn: 'root' })
export class ImageClient {
    private readonly _client = inject(HttpClient);

    upload(file: File | Blob) {
        const formData = new FormData();
        formData.append('', file);
        return this._client.post(basePath, formData, {
            responseType: 'text',
        });
    }
}
