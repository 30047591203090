import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { SafeContentPipe } from '../../core/pipe/safe-content.pipe';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TreatmentFileKind, TreatmentFiles } from '../../core/model/treatment-file.model';
import { filter, fromEvent, shareReplay, take } from 'rxjs';
import { TreatmentTypeOriginalId } from '../../core/model/treatmentType';
import { ModelColors } from '../../core/model/model-colors.model';
import { orderBy, trimStart } from 'lodash-es';
import { FileExtensions } from '../../core/model/file-extensions';

export interface ThreedViewerDialogData {
    designFiles?: TreatmentFiles;
    fileDownloadLinks?: Map<string, string>;
    treatmentType?: TreatmentTypeOriginalId;
    patientFiles?: TreatmentFiles;
}

interface ThreedViewerParams {
    treatmentType?: number | null;
    isSingleModel: boolean;
    modelList: {
        modelId: string;
        isScan: boolean;
        modelName: string;
        url: string;
        fileType: string;
        meshColor: string;
        crossColor?: string;
        modelType: number;
        isHidden: boolean;
    }[];
}

@Component({
    selector: 'app-threed-viewer-dialog',
    standalone: true,
    imports: [CommonModule, SafeContentPipe],
    template: `
        <div class="d-flex flex-column h-100 w-100">
            <iframe #iframe [src]="threedViewerUrl | safeContent : 'resourceUrl'" width="100%" height="800px"></iframe>
        </div>
    `,
    styleUrls: ['./threed-viewer-dialog.component.scss'],
})
export class ThreedViewerDialogComponent implements OnInit, OnDestroy {
    @ViewChild('iframe', { static: true }) iframeElementRef?: ElementRef;

    readonly threedViewerUrl = environment.threedViewerUrl;

    private readonly _dialogConfig = inject<DynamicDialogConfig<ThreedViewerDialogData>>(
        DynamicDialogConfig<ThreedViewerDialogData>,
        { optional: true }
    );
    private readonly _data = this._dialogConfig?.data;
    private readonly _iframeReady$ = fromEvent(window, 'message').pipe(
        filter(e => e instanceof MessageEvent && e.origin === this.threedViewerUrl && e.data.pageLoaded),
        shareReplay(1)
    );

    ngOnInit(): void {
        const { _data } = this;
        if (_data) {
            const { designFiles, fileDownloadLinks, treatmentType, patientFiles } = _data;
            const treatmentColorConfig = ModelColors.find(item => item.treatmentType === treatmentType);

            const allScans =
                patientFiles?.filter(
                    item =>
                        (item.fileKind === TreatmentFileKind.Original ||
                            item.fileKind === TreatmentFileKind.ImprovedOriginal) &&
                        item.fileExtension === FileExtensions.stl
                ) ?? [];
            const improvedScans = allScans.filter(item => item.fileKind === TreatmentFileKind.ImprovedOriginal);
            // Only include original scan whose file type not appeared in the improvedScans
            const scans = [
                ...allScans.filter(
                    item =>
                        item.fileKind === TreatmentFileKind.Original &&
                        !improvedScans.some(improvedScan => improvedScan.fileType === item.fileType)
                ),
                ...improvedScans,
            ];

            const scanModelList = orderBy(
                scans.map(f => {
                    return {
                        modelId: f.fileGuid,
                        isScan: true,
                        modelName: f.friendlyName,
                        url: fileDownloadLinks?.get(f.fileGuid) ?? '',
                        fileType: trimStart(f.fileExtension, '.') ?? '',
                        meshColor: '#1EE408',
                        modelType: f.fileType,
                        isHidden: true,
                    };
                }),
                'modelType'
            );
            const modelList = designFiles?.map<ThreedViewerParams['modelList'][number]>(f => {
                const outputModelColorConfig = treatmentColorConfig?.treatmentFiles?.find(
                    item => item.fileType === f.fileType
                );
                const outputModelColor = outputModelColorConfig ? outputModelColorConfig : treatmentColorConfig;

                return {
                    modelId: f.fileGuid,
                    isScan: false,
                    modelName: f.friendlyName,
                    url: fileDownloadLinks?.get(f.fileGuid) ?? '',
                    fileType: trimStart(f.fileExtension, '.') ?? '',
                    meshColor: outputModelColor?.modelColor ?? '',
                    crossColor: outputModelColor?.crossColor ?? '',
                    modelType: f.fileType,
                    isHidden: false,
                };
            });
            if (modelList) {
                const models = scanModelList ? [...scanModelList, ...modelList] : modelList;
                this._iframeReady$.pipe(take(1)).subscribe(() => {
                    const iframe = this.iframeElementRef?.nativeElement;
                    if (iframe instanceof HTMLIFrameElement) {
                        const payload: ThreedViewerParams = {
                            treatmentType,
                            modelList: scanModelList ? [...scanModelList, ...modelList] : modelList,
                            isSingleModel: models.length < 2,
                        };
                        iframe.contentWindow?.postMessage(payload, '*');
                    }
                });
            }
        }
    }

    ngOnDestroy(): void {}
}
