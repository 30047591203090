import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { filter, first, Observable, of, switchMap, tap } from 'rxjs';
import { UserStore } from '../store';
import { logger } from '../utils/logger';

const excludePaths: (string | RegExp)[] = [
    '/api/server-metadata',
    'design-lab-service-files-dev.s3.cn-northwest-1.amazonaws.com.cn',
    'design-lab-service-files-prod.s3.cn-northwest-1.amazonaws.com.cn',
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private readonly _user = inject(UserStore);

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return excludePaths.some(e => (typeof e === 'string' ? request.url.includes(e) : e.test(request.url))) ||
            request.headers.get('Authorization')
            ? next.handle(request)
            : this._user.token$.pipe(
                  first(),
                  switchMap(token => {
                      const remainValidSeconds = (token.accessTokenPayload.exp * 1000 - Date.now()) / 1000;
                      return remainValidSeconds > 3 * 60
                          ? of(token)
                          : this._user.refreshToken().pipe(
                                filter(newToken => newToken.accessToken !== token.accessToken),
                                first(),
                                tap(newToken => {
                                    logger.logInformation(
                                        `token refreshed, oldToken remainSeconds: ${remainValidSeconds}, newToken.exp: ${newToken.accessTokenPayload.exp}`
                                    );
                                })
                            );
                  }),
                  switchMap(token =>
                      next.handle(
                          request.clone({
                              headers: new HttpHeaders({
                                  Authorization: `Bearer ${token.accessToken}`,
                              }),
                          })
                      )
                  )
              );
    }
}
