import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import { NavMenuComponent } from 'src/app/components/nav-menu/nav-menu.component';
import localeZh from '@angular/common/locales/zh';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { logger } from './core/utils/logger';
import { UserStore } from './core/store/user.store';
import { RxHelper } from './core/utils/rxjxHelper';
import { combineLatest } from 'rxjs';
import { StyleClassModule } from 'primeng/styleclass';
import { DialogService } from 'primeng/dynamicdialog';
import { MemberSelectDialogService } from './components/dialogs/members-select-dialog';

@Component({
    selector: 'app-root',
    template: `
        <app-nav-menu></app-nav-menu>
        <main class="container-fluid">
            <router-outlet></router-outlet>
        </main>
    `,
    standalone: true,
    imports: [NavMenuComponent, RouterModule, CommonModule, StyleClassModule],
    providers: [RxHelper, DialogService, MemberSelectDialogService],
})
export class AppComponent {
    constructor(
        private readonly config: PrimeNGConfig,
        private readonly translateService: TranslateService,
        private readonly userStore: UserStore,
        private readonly rxHelper: RxHelper,
        private readonly router: Router,
        httpClient: HttpClient
    ) {
        logger.init(httpClient);
        registerLocaleData(localeZh, 'zh');

        this.config.zIndex = {
            modal: 21000,
            overlay: 20001, // dropdown, overlaypanel
            menu: 20001,
            tooltip: 21000,
        };
        this.translateService
            .get('primeng')
            .pipe(take(1))
            .subscribe(res => config.setTranslation(res));

        this.ensureAccessible();
    }

    private ensureAccessible() {
        combineLatest([
            this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)),
            this.userStore.currentUser$,
        ])
            .pipe(this.rxHelper.autoUnsubscribe)
            .subscribe(([ev, u]) => {
                if (ev.url.includes('403.html')) {
                    return;
                }
                const isDoctor = u.roles.some(iRole => iRole.toLowerCase().includes('doctor'));
                if (isDoctor) {
                    this.router.navigateByUrl(`/403.html`);
                }
            });
    }
}
