import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TreatmentReturnRequestClient } from '../../core/client/treatment-return-request-client.service';
import { RxHelper } from '../../core/utils/rxjxHelper';
import { firstValueFrom } from 'rxjs';
import { logger } from '../../core/utils/logger';
import {
    createTreatmentReturnRequestReviewForm,
    TreatmentReturnRequestReplyEditComponent,
} from '../treatment-return-request-review-edit';
import { toFormData } from '../../core/utils/formData';
import { pick } from 'lodash-es';
import { Base64ImageExtractService } from '../../core/service/base64-image-extractor.service';

export interface TreatmentReturnRequestReviewEditDialogData {
    treatmentReturnRequestId: number;
    treatmentId: string;
    operation: number;
}

const selector = 'app-treatment-return-request-reply-confirm-dialog';

@Component({
    selector: selector,
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, ButtonModule, TreatmentReturnRequestReplyEditComponent],
    template: `
        <div class="d-flex modal-body flex-column overflow-hidden h-100">
            <div class="flex-grow-1 d-flex flex-column overflow-hidden">
                <app-treatment-return-request-reply-edit class="flex-grow-1" [form]="form" />
            </div>
            <div style="text-align: right" class="mt-3">
                <p-button
                    label="取消"
                    [text]="true"
                    styleClass="p-button-text p-button-sm p-button-secondary"
                    (onClick)="onCancel()"
                />
                <p-button
                    label="确定"
                    (onClick)="onSave()"
                    [text]="true"
                    [loading]="isSaving"
                    styleClass="p-button-text p-button-sm p-button-info"
                    [disabled]="form.disabled || form.invalid"
                />
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                height: 100%;
                overflow: hidden;
            }
        `,
    ],
    providers: [RxHelper],
})
export class TreatmentReturnRequestReplyConfirmDialog implements OnInit {
    private readonly _dialogConfig = inject<DynamicDialogConfig<TreatmentReturnRequestReviewEditDialogData>>(
        DynamicDialogConfig,
        {
            optional: true,
        }
    );
    private readonly _dialogRef = inject(DynamicDialogRef, { optional: true });
    private readonly _apiClient = inject(TreatmentReturnRequestClient);
    private readonly _imageExtractor = inject(Base64ImageExtractService);

    protected readonly form = createTreatmentReturnRequestReviewForm();
    protected isSaving = false;

    ngOnInit() {
        logger.logInformation(`[${selector}] opened`);
        const dialogData = this._dialogConfig?.data;
        if (dialogData) this.form.patchValue({ ...pick(dialogData, ['treatmentReturnRequestId', 'operation']) });
    }

    protected async onSave() {
        this.form.disable();
        this.isSaving = true;
        const rawValue = this.form.getRawValue();
        const treatmentId = this._dialogConfig?.data?.treatmentId;
        let hasSaved = false;
        if (rawValue && treatmentId) {
            rawValue.body = rawValue.body ? await this._imageExtractor.extract(rawValue.body) : rawValue.body;
            await firstValueFrom(this._apiClient.reply(treatmentId, toFormData(rawValue))).then(
                () => (hasSaved = true),
                reason => {
                    hasSaved = false;
                    logger.logError(
                        `Failed to save treatmentReturnRequest, treatment: ${treatmentId}, ${JSON.stringify(
                            rawValue
                        )}, ${JSON.stringify(reason)}`
                    );
                }
            );
        }

        this.isSaving = false;
        this.form.enable();
        if (hasSaved) {
            logger.logInformation(`[${selector}] saved`);
            this._dialogRef?.close(true);
        }
    }

    protected onCancel() {
        logger.logInformation(`[${selector}] onCancel`);
        this._dialogRef?.close(false);
    }
}
