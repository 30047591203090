export interface TreatmentFile {
    id: number;
    originalId: number;
    fileId: number;
    treatmentId: string;
    fileType: TreatmentFileTypeEnum;
    fileKind: TreatmentFileKind;
    order?: number | null;
    teeth?: number | null;
    createdDate: string | Date;
    modifiedDate?: string | Date | null;
    fileExtension?: string;
    name: string;
    size: number;
    friendlyName: string;
    fileGuid: string;
    designId?: number;
    isDownloading?: boolean;
    messageId?: string | null;
    message?: string | null;
}

export interface TreatmentFileWithDownloadUrl extends TreatmentFile {
    downloadUrl?: string | null;
}

export enum TreatmentFileKind {
    Original = 0,
    Design = 1,
    Other = 2,
    Revision = 3,
    ImprovedOriginal = 4,
    Engineering = 5,
    AiDesign = 6,
    PolishedOriginal = 7,
}

export type TreatmentFiles = TreatmentFile[];

export enum TreatmentFileTypeEnum {
    UpperJaw = 1,
    LowerJaw = 2,
    LeftSide = 3,
    RightSide = 4,
    Other = 5,
    Spr = 6,
    SingleStl = 7,
    DesignPhoto = 8,
    CBCT = 9,
    SingleStlWithSupports = 10,
    BaseStl = 11,
    BaseSpr = 12,
    PonticStl = 13,
    PonticSpr = 14,
    PatientPhoto = 15,
    SurgicalGuideStl = 16,
    SurgicalGuideSpr = 17,
    TemporaryCrownStl = 18,
    TemporaryCrownSpr = 19,
    RemovableDieStl = 20,
    RemovableDieSpr = 21,
    CustomBleachingTrayStl = 22,
    CustomBleachingTraySpr = 23,
    WaxUpUpperStl = 24,
    TrialSmileUpperStl = 25,
    WaxUpSpr = 26,
    TrialSmileSpr = 27,
    DesignVideo = 28,
    MonolithicTryInDentureStl = 29,
    MonolithicTryInDentureSpr = 30,
    DentureGumBaseStl = 31,
    DentureGumBaseSpr = 32,
    DentureTeethStl = 33,
    DentureTeethSpr = 34,
    WaxUpLowerStl = 35,
    TrialSmileLowerStl = 36,
    CephXRayPhoto = 37,
    PanoXRayPhoto = 38,
    FrontFace = 39,
    FrontSmile = 40,
    RightSideFace = 41,
    LeftSideFace = 42,
    FrontTeeth = 43,
    RightSideTeeth = 44,
    LeftSideTeeth = 45,
    UpperJawImage = 46,
    LowerJawImage = 47,
    PreppedToothIntraoralScans = 48,
    DentureWaxSetup = 49,
    UpperTissueScan = 50,
    LowerTissueScan = 51,
    PhotogrammetryData = 52,
    MonolithicHybridDenturesStl = 53,
    MonolithicHybridDenturesSpr = 54,
    AICrownPreviewImage = 55,
    AICrownStl = 56,
    AICrownDieStl = 57,
    BiteScanCombo = 58,
    DentureUpperStl = 59,
    DentureLowerStl = 60,
    SmileDesignStl = 61,
    SmileDesignFrontSmile = 63,
    UpperJawRetainer = 64,
    LowerJawRetainer = 65,
    UpperJawAligner = 66,
    LowerJawAligner = 67,
    SprRetainer = 68,
    SprAligner = 69,
    UpperAppliance = 70,
    LowerAppliance = 71,
    UpperAntagonist = 72,
    LowerAntagonist = 73,
    VeneersDesignFrontSmile = 74,
    VeneersStl = 75,
    VeneersSpr = 76,
}
