import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, ReplaySubject, shareReplay } from 'rxjs';
import { UserToken } from 'src/app/core/model/user-token.model';
import { AuthService } from 'src/app/core/service/auth.service';
import { UserProfile } from '../model/user.profile.model';
import { MemberClient } from '../client/member-client.service';
import { Roles } from '../constants';

@Injectable({ providedIn: 'root' })
export class UserStore {
    private readonly _tokenSource$ = new ReplaySubject<UserToken>(1);
    private _token$?: Observable<UserToken> | null;
    private _currentUser$?: Observable<UserProfile> | null;
    private _avatarUrl$?: Observable<string> | null;
    private _currentUserId$?: Observable<string> | null;
    private _currentUserRole$?: Observable<string[]> | null;
    private _isDesigner$?: Observable<boolean> | null;
    private _isReviewer$?: Observable<boolean> | null;
    private _isAnyAdmin$?: Observable<boolean> | null;

    constructor(private readonly _authService: AuthService, private readonly _userClient: MemberClient) {}

    get token$(): Observable<UserToken> {
        return (this._token$ ??= this.refreshToken());
    }

    get currentUser$(): Observable<UserProfile> {
        return (this._currentUser$ ??= this._userClient.getProfile().pipe(shareReplay(1)));
    }

    get currentUserId$(): Observable<string> {
        return (this._currentUserId$ ??= this.currentUser$.pipe(
            map(x => x.id),
            shareReplay(1)
        ));
    }

    get currentUserRoles$(): Observable<string[]> {
        return (this._currentUserRole$ ??= this.currentUser$.pipe(
            map(x => x.roles),
            shareReplay(1)
        ));
    }

    get isDesigner$(): Observable<boolean> {
        return (this._isDesigner$ ??= this.currentUserRoles$.pipe(
            map(x => x.includes(Roles.designer) && !x.includes(Roles.reviewer)),
            shareReplay(1)
        ));
    }

    get isReviewer$(): Observable<boolean> {
        return (this._isReviewer$ ??= this.currentUserRoles$.pipe(
            map(x => x.includes(Roles.reviewer)),
            shareReplay(1)
        ));
    }

    get isAnyAdmin$(): Observable<boolean> {
        return (this._isAnyAdmin$ ??= this.currentUserRoles$.pipe(
            map(x => x.some(i => i.toLowerCase().includes('admin'))),
            shareReplay(1)
        ));
    }

    get avatarUrl$() {
        return (this._avatarUrl$ ??= this.currentUser$.pipe(map(u => u.picture)));
    }

    refreshToken() {
        this.refreshTokenAsync().then(() => {});
        return this._tokenSource$;
    }

    async refreshTokenAsync() {
        this._tokenSource$.next(await this.fetchTokenAsync());
    }

    fetchTokenAsync() {
        return firstValueFrom(this._authService.fetchTokens());
    }
}
