import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    TreatmentDesignRevisionJudgement,
    TreatmentDesignRevisionJudgementEditModel,
    TreatmentDesignRevisionLiability,
} from '../model';
import { TreatmentTypeOriginalId } from '../model/treatmentType';
import { TreatmentDesignRevisionReason } from '../model';
import { pick } from 'lodash-es';
import { catchError, Observable, of } from 'rxjs';

const basePath = '/api';

@Injectable({ providedIn: 'root' })
export class TreatmentDesignRevisionClient {
    protected _httpClient = inject(HttpClient);

    saveRevisionJudgement(id: number, body: TreatmentDesignRevisionJudgementEditModel) {
        return this._httpClient.post<TreatmentDesignRevisionJudgement>(
            `${basePath}/design-revision-judgements/${id}`,
            pick(body, 'id', 'reasonId', 'liability')
        );
    }

    listRevisionReasons(treatmentTypeId: TreatmentTypeOriginalId, liability: TreatmentDesignRevisionLiability) {
        return this._httpClient.get<TreatmentDesignRevisionReason[]>(
            `${basePath}/design-revision-judgements/reasons?treatmentTypeOriginalId=${treatmentTypeId}&liability=${liability}`
        );
    }

    getReasonById(reasonId: number): Observable<TreatmentDesignRevisionReason | null | undefined> {
        return this._httpClient
            .get<TreatmentDesignRevisionReason | null>(`${basePath}/design-revision-judgements/reasons/${reasonId}`)
            .pipe(catchError(() => of(null)));
    }
}
