<div class="d-flex flex-column h-100">
    <div class="members-select__search-key mb-3 d-flex align-items-center gap-2">
        <div class="flex-grow-1">
            <div class="p-inputgroup">
                <input
                        type="text"
                        class="w-100"
                        pInputText
                        placeholder="搜索用户(至少输入3个字符)..."
                        [ngModel]="searchKey$ | async"
                        (ngModelChange)="onSearch($event)"
                />
                <button
                        type="button"
                        pButton
                        label="搜索"
                        class="p-button-sm members-select__search-button"
                        [loading]="isLoadingMembers"
                        [disabled]="isLoadingMembers"
                        (click)="onClickSearch()"
                ></button>
            </div>
        </div>


    </div>

    <div class="members-select__items-container flex-grow-1 overflow-y-auto">
        <div *ngIf="isLoadingMembers" class="py-3">
            <app-loading/>
        </div>
        <div
                *ngFor="let iMember of members"
                class="members-select__control-item card"
                (click)="selections.toggle(iMember); onTouchedFn?.(); onChangeFn?.(selections.selected); "
        >
            <p-checkbox
                    name="group"
                    [value]="selections.isSelected(iMember)"
                    [binary]="true"
                    [ngModel]="selections.isSelected(iMember)"
                    [inputId]="'member__' + iMember.id"
            ></p-checkbox>

            <app-member-item [member]="iMember" [hideEmail]="true" [showMainRole]="true"/>
        </div>
    </div>
</div>
