import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { createDoctorPreferenceEditForm } from '../../../core/model/doctor-preference.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DoctorPreferenceClient } from '../../../core/client/doctor-preference-client';
import { EditorModule } from 'primeng/editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { catchError, first, map, of } from 'rxjs';
import { DoctorPreferenceEditDialogConfig } from './doctor-preference-edit-dialog-config';

@Component({
    selector: 'app-doctor-preference-edit-dialog',
    standalone: true,
    imports: [CommonModule, EditorModule, FormsModule, ReactiveFormsModule, ButtonModule],
    template: `
        <div class="d-flex flex-column h-100">
            <div class="flex-grow-1 d-flex gap-2 flex-column">
                <div class="d-flex ">
                    <div class="text-nowrap">
                        <small>原文:</small>
                        &nbsp;
                    </div>
                    <div class="flex-grow-1">
                        <p-editor
                            placeholder="在此输入默认要求原文"
                            [style]="{ height: '200px', width: '100%' }"
                            [formControl]="form.controls.designPreference"
                        />
                    </div>
                </div>

                <div class="d-flex ">
                    <div class="text-nowrap">
                        <small>译文:</small>
                        &nbsp;
                    </div>
                    <div class="flex-grow-1">
                        <p-editor
                            placeholder="在此输入默认要求译文"
                            [style]="{ height: '200px', width: '100%' }"
                            [formControl]="form.controls.translation"
                        />
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end mt-4">
                <p-button
                    class="me-3"
                    styleClass="p-button-sm p-button-primary p-button-secondary p-button-outlined p-button-sm"
                    [outlined]="true"
                    label="取消"
                    [disabled]=""
                    (onClick)="onCancel()"
                />
                <p-button
                    [loading]="isSubmitting"
                    [disabled]="isSubmitting || form.pristine || form.invalid"
                    styleClass="p-button-sm p-button-primary p-button-outlined p-button-sm"
                    [outlined]="true"
                    label="保存"
                    (onClick)="onConfirm()"
                />
            </div>
        </div>
    `,
    styleUrls: ['./doctor-preference-edit-dialog.component.scss'],
})
export class DoctorPreferenceEditDialogComponent implements OnInit {
    readonly form = createDoctorPreferenceEditForm();
    isSubmitting = false;

    protected readonly ref = inject(DynamicDialogRef, { optional: true });

    private readonly _config = inject<DynamicDialogConfig<DoctorPreferenceEditDialogConfig>>(DynamicDialogConfig, {
        optional: true,
    });
    private readonly _httpClient = inject(DoctorPreferenceClient);

    constructor() {}

    ngOnInit() {
        if (this._config?.data?.doctorPreference) this.form.patchValue(this._config?.data?.doctorPreference);
    }

    protected onConfirm() {
        if (!this._config?.data?.treatmentId) return;
        this.isSubmitting = true;
        this._httpClient
            .save(this._config?.data?.treatmentId, this._config?.data?.doctorPreference?.id, this.form.getRawValue())
            .pipe(
                catchError(() => of(false)),
                map(() => true),
                first()
            )
            .subscribe(r => {
                this.isSubmitting = false;
                this.ref?.close({ saved: r });
            });
    }

    protected onCancel() {
        this.ref?.close({ saved: false });
    }
}

export * from './doctor-preference-edit-dialog-config';
