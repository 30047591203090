import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Member, Members } from '../../core/model/member.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MemberClient } from '../../core/client';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    firstValueFrom,
    of,
} from 'rxjs';
import { RxHelper } from '../../core/utils/rxjxHelper';
import { MemberItemComponent } from '../member-item/member-item.component';
import { Role, Roles } from '../../core/constants';
import { sortBy, uniqBy } from 'lodash-es';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingComponent } from '../loading/loading.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { PageModel } from '../../core/model/page-model.model';

@Component({
    selector: 'app-members-select',
    standalone: true,
    imports: [
        CommonModule,
        MemberItemComponent,
        InputTextModule,
        PaginatorModule,
        ProgressSpinnerModule,
        LoadingComponent,
        CheckboxModule,
        ButtonModule,
    ],
    templateUrl: './members-select.component.html',
    styleUrls: ['./members-select.component.scss'],
    providers: [
        RxHelper,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MembersSelectComponent),
            multi: true,
        },
    ],
})
export class MembersSelectComponent implements ControlValueAccessor, OnInit {
    @Input() excludeUserIds?: string[] | null;
    @Input() excludeRoles?: Role[] | null;
    @Input() multi = true;

    isDisabled = false;
    members?: Members;
    isLoadingMembers = false;

    protected readonly loadMembersSource$ = new BehaviorSubject(Date.now());
    protected readonly searchKey$ = new BehaviorSubject<string | null | undefined>(undefined);
    protected selections = new SelectionModel<Member>(true, undefined, undefined, (x, y) => x.id === y.id);

    protected onChangeFn?: (value: Members) => unknown;
    protected onTouchedFn?: () => unknown;

    constructor(private readonly _membersClient: MemberClient, private readonly _rxHelper: RxHelper) {}

    ngOnInit() {
        this.loadMembersSource$.pipe(distinctUntilChanged(), this._rxHelper.autoUnsubscribe).subscribe(() => {
            this.loadMembers();
        });
        if (!this.multi)
            this.selections = new SelectionModel<Member>(false, undefined, undefined, (x, y) => x.id === y.id);

        this.searchKey$
            .pipe(
                filter((x, idx) => (x != null && x.length > 2) || idx > 0),
                debounceTime(1500),
                filter(() => !this.isLoadingMembers),
                this._rxHelper.autoUnsubscribe
            )
            .subscribe(() => {
                this.loadMembersSource$.next(Date.now());
            });
    }

    async writeValue(inputValue: any): Promise<void> {
        const membersToSelect: Members = [];
        if (Array.isArray(inputValue)) {
            if (inputValue.every(iItem => typeof iItem === 'object')) membersToSelect.push(...inputValue);
            else if (inputValue.every(iItem => typeof iItem === 'string')) {
                membersToSelect.push(
                    ...(await firstValueFrom(
                        this._membersClient.listMembers({
                            userIds: inputValue,
                        })
                    ).then(({ items }) => items))
                );
            }
        } else if (typeof inputValue === 'object') {
            membersToSelect.push(inputValue);
        } else {
            return;
        }

        this.selections.clear();
        this.selections.select(...membersToSelect);
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    protected onSearch(value?: string | null) {
        this.onTouchedFn?.();
        this.searchKey$.next(value);
    }

    private loadMembers() {
        this.isLoadingMembers = true;

        const searchKey = this.searchKey$.value;

        const excludeDesigner = this.excludeRoles?.includes(Roles.designer) ?? false;
        const filteredAdminRoles = [Roles.reviewer, Roles.reviewLeader, Roles.designLeader, Roles.labAdmin].filter(
            x => this.excludeRoles == null || !this.excludeRoles.includes(x)
        );
        const emptyPageModelSource = of<PageModel<Member>>({ items: [], pageIndex: 1, pageSize: 1, total: 0 });
        combineLatest([
            filteredAdminRoles.length > 0
                ? this._membersClient.listMembers({
                      roles: filteredAdminRoles,
                      searchKey: searchKey ? searchKey : undefined,
                      exclude: this.excludeUserIds ?? undefined,
                  })
                : emptyPageModelSource,
            excludeDesigner
                ? emptyPageModelSource
                : this._membersClient.listMembers({
                      roles: [Roles.designer],
                      excludeRoles: [Roles.reviewer, Roles.reviewLeader, Roles.designLeader, Roles.labAdmin],
                      searchKey: searchKey ? searchKey : undefined,
                      exclude: this.excludeUserIds ?? undefined,
                  }),
        ])
            .pipe(first())
            .subscribe({
                next: ([{ items: nonDesigners }, { items: designers }]) => {
                    this.members = uniqBy(
                        [
                            ...sortBy(nonDesigners, ({ mainRole }) => {
                                if (mainRole === Roles.designLeader) return 0;
                                else if (mainRole === Roles.reviewLeader) return 1;
                                else if (mainRole === Roles.reviewer) return 2;

                                return 3;
                            }),
                            ...designers,
                        ],
                        x => x.id
                    );
                },
                complete: () => {
                    this.isLoadingMembers = false;
                },
            });
    }

    protected onClickSearch() {
        this.loadMembersSource$.next(Date.now());
    }
}
