import { DesignRevisionLiabilityOption, TreatmentDesignRevisionLiability } from '../model';

export const DesignRevisionLiabilityOptions: DesignRevisionLiabilityOption[] = [
    {
        liability: TreatmentDesignRevisionLiability.designer,
        title: '设计师责任',
    },
    {
        liability: TreatmentDesignRevisionLiability.doctor,
        title: '医生责任',
    },
    {
        liability: TreatmentDesignRevisionLiability.pageUx,
        title: '订单页面问题',
    },
    {
        liability: TreatmentDesignRevisionLiability.unnecessary,
        title: '未返修',
    },
    {
        liability: TreatmentDesignRevisionLiability.unknown,
        title: '无法判定',
    },
    {
        liability: TreatmentDesignRevisionLiability.pending,
        title: '待定',
    },
];
