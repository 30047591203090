import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DoctorPreference, DoctorPreferenceEditModel } from '../model/doctor-preference.model';

const basePath = '/api';

@Injectable({ providedIn: 'root' })
export class DoctorPreferenceClient {
    private readonly _http = inject(HttpClient);

    listByTreatmentId(treatmentId: string) {
        return this._http.get<DoctorPreference[]>(`${basePath}/treatments/${treatmentId}/doctor-preferences`);
    }

    save(treatmentId: string, doctorPreferenceId: string | null | undefined, model: DoctorPreferenceEditModel) {
        return this._http.post<{}>(
            `${basePath}/treatments/${treatmentId}/doctor-preferences/${doctorPreferenceId ?? ''}`,
            {
                ...model,
            }
        );
    }
}
