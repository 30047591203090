import { ChangeDetectorRef, Component, inject, Input, OnChanges, SimpleChange } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberAvatarComponent } from '../member-avatar/member-avatar.component';
import { Member } from '../../core/model/member.model';
import { Avatar } from 'primeng/avatar';
import { MemberClient } from '../../core/client/member-client.service';
import { UserStore } from '../../core/store/user.store';
import { combineLatestWith, first, ReplaySubject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-member-item',
    standalone: true,
    imports: [CommonModule, MemberAvatarComponent, TranslateModule],
    template: `
        <div
            class="d-flex flex-row align-items-stretch gap-2"
            [ngClass]="{
                'align-items-stretch': !hideEmail,
                'align-items-center': hideEmail
            }"
            [class.align-items-center]="reverse"
            [class.flex-row-reverse]="reverse"
        >
            <app-member-avatar [size]="size" [member]="member" [class.d-flex]="hideEmail" *ngIf="!hideAvatar" />
            <div class="d-flex flex-column justify-content-between">
                <div [class.text-nowrap]="nameNoWrap">
                    <small *ngIf="size === 'normal'">{{ fullName$ | async }}</small>
                    <ng-container *ngIf="size !== 'normal'">{{ fullName$ | async }}</ng-container>
                </div>
                <div class="text-muted " *ngIf="!hideEmail">
                    <small *ngIf="size === 'normal'">{{ member?.email ?? '' }}</small>
                    <ng-container *ngIf="size !== 'normal'">{{ member?.email ?? '' }}</ng-container>
                </div>
                <div *ngIf="showMainRole && member?.mainRole">
                    <small>
                        <span class="pi pi-user"></span>
                        &nbsp;
                        {{ 'roles.' + (member?.mainRole ?? '') | translate }}
                    </small>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                display: inline-block;
            }
        `,
    ],
    host: {
        '[class]': `['d-inline-flex', 'align-items-center', 'flex-row']`,
    },
})
export class MemberItemComponent implements OnChanges {
    @Input() member?: Member | null;
    @Input() memberId?: string | null;
    @Input() size: Avatar['size'] = 'large';
    @Input() reverse = false;
    @Input() hideEmail = false;
    @Input() useYou = false;
    @Input() hideAvatar = false;
    @Input() showMainRole = false;
    @Input() nameNoWrap = false;

    protected readonly fullName$ = new ReplaySubject(1);

    private readonly _userClient = inject(MemberClient);
    private readonly _cdr = inject(ChangeDetectorRef);
    private readonly _userStore = inject(UserStore);

    ngOnChanges(changes: Record<keyof MemberItemComponent, SimpleChange>): void {
        if (changes.memberId && this.memberId && this.member?.id !== this.memberId) {
            this._userClient
                .getById(this.memberId)
                .pipe(combineLatestWith(this._userStore.currentUser$), first())
                .subscribe(([x, { id }]) => {
                    this.member ??= x;
                    this.fullName$.next(
                        this.useYou && (id == this.member?.id || id === this.memberId)
                            ? '你'
                            : this.member?.fullName ?? ''
                    );
                    this._cdr.markForCheck();
                });
        }
        if (changes.member && this.member && this.member.fullName) {
            this._userStore.currentUser$.pipe(first()).subscribe(({ id }) => {
                this.fullName$.next(
                    this.useYou && (id == this.member?.id || id === this.memberId) ? '你' : this.member?.fullName ?? ''
                );
            });
        }
    }
}
