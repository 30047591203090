import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { TreatmentDeliverProgressRecord } from '../model/treatment-deliver-progress.model';
import { UserToken } from '../model/user-token.model';

const basePath = '/api/treatments';

@Injectable({
    providedIn: 'root',
})
export class TreatmentDeliverClient {
    private _client = inject(HttpClient);

    constructor() {}

    getTreatmentDeliverProgressRecord(treatmentIds: string[]): Observable<TreatmentDeliverProgressRecord> {
        return treatmentIds.length <= 0
            ? of({})
            : this._client.get<TreatmentDeliverProgressRecord>(`${basePath}/deliveries/progresses`, {
                  params: treatmentIds.reduce((acc, c) => acc.append('treatmentIds', c), new HttpParams()),
              });
    }

    triggerTreatmentDelivery(treatmentIds: string[], token: UserToken): Observable<void> {
        return this._client.post<void>(`${basePath}/deliveries:trigger`, treatmentIds, {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
            },
        });
    }
}
