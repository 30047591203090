import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {MemberClient} from '../../core/client/member-client.service';
import {BehaviorSubject, debounce, interval, shareReplay, switchMap, take, tap} from 'rxjs';
import {MemberAvatarComponent} from '../member-avatar/member-avatar.component';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RxHelper} from '../../core/utils/rxjxHelper';
import {Member} from '../../core/model/member.model';
import {MemberItemComponent} from '../member-item/member-item.component';

@Component({
    selector: 'app-reviewer-select',
    standalone: true,
    imports: [
        CommonModule,
        MemberAvatarComponent,
        FormsModule,
        RadioButtonModule,
        ReactiveFormsModule,
        InputTextModule,
        ProgressSpinnerModule,
        MemberItemComponent,
    ],
    template: `
        <div>
            <div class="pt-2 pb-3 d-flex align-items-center">
                <span class="p-input-icon-left" *ngIf="showSearch">
                    <i class="pi pi-search"></i>
                    <input
                            type="text"
                            style="min-width: 30vw"
                            pInputText
                            [ngModel]="searchName$ | async"
                            placeholder="搜索用户(至少输入3个字符)..."
                            (ngModelChange)="onInputSearchName($event)"
                    />
                </span>

                <span class="ms-3">
                    <p-progressSpinner
                            *ngIf="loading$ | async"
                            [style]="{ width: '40px', height: '40px' }"
                            strokeWidth="3"
                            fill="var(--surface-ground)"
                            animationDuration=".5s"
                    />
                </span>
            </div>
            <div class="row row-cols-1 row-cols-md-2 g-3">
                <div class="col" *ngFor="let member of reviewers$ | async">
                    <div class="card" (click)="label.click(); onTouch()">
                        <div class="card-body d-flex align-items-center">
                            <p-radioButton
                                    name="reviewer"
                                    [value]="member.id"
                                    [formControl]="control"
                                    [inputId]="member.id"
                            />
                            <label #label [for]="member.id" class="ms-2">
                                <app-member-item [member]="member" [hideEmail]="true" [showMainRole]="true"/>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./reviewer-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RxHelper,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReviewerSelectComponent),
            multi: true,
        },
    ],
})
export class ReviewerSelectComponent implements OnInit, ControlValueAccessor {
    @Input() placeHolder = '选择质检员';
    @Input() disable = false;
    @Input() showSearch = true;

    @Output() select = new EventEmitter<Member | undefined | null>();

    readonly loading$ = new BehaviorSubject<boolean>(true);
    protected readonly searchName$ = new BehaviorSubject<string | null | undefined>(undefined);
    protected reviewers$ = this.searchName$.pipe(
        debounce(v => (v && v.length >= 0 ? interval(1500) : interval(0))),
        tap(() => this.loading$.next(true)),
        switchMap(s => this._memberClient.listReviewers({searchName: s})),
        tap(() => this.loading$.next(false)),
        shareReplay(1)
    );
    protected readonly control = new FormControl<string | undefined | null>(undefined);
    protected onTouch = () => {
    };
    private readonly _rxHelper = inject(RxHelper);

    constructor(private readonly _memberClient: MemberClient) {
    }

    ngOnInit(): void {
    }

    onInputSearchName(value: string) {
        this.onTouch();
        this.searchName$.next(value);
    }

    registerOnChange(fn: any): void {
        this.control.valueChanges.pipe(this._rxHelper.autoUnsubscribe).subscribe(v => {
            this.reviewers$.pipe(take(1)).subscribe(r => this.select.emit(r.find(i => i.id === v)));
            fn(v);
        });
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(v: unknown): void {
        if (v == null) this.control.setValue(v);
        else if (typeof v === 'string') this.control.setValue(v);
    }

    setDisabledState(isDisabled: boolean): void {
        this.disable = isDisabled;
    }
}
