import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnChanges,
    SimpleChange,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Member } from '../../core/model/member.model';
import { Avatar, AvatarModule } from 'primeng/avatar';
import { MemberClient } from '../../core/client/member-client.service';

@Component({
    selector: 'app-member-avatar',
    standalone: true,
    imports: [CommonModule, AvatarModule],
    template: `
        <p-avatar
            class="d-inline-flex align-items-center"
            [label]="
                member == null || member.picture
                    ? undefined
                    : (member?.firstName?.[0]?.toUpperCase() ?? '') + member?.lastName?.[0]?.toUpperCase()
            "
            shape="circle"
            [style]="{ 'background-color': backgroundColor, color: '#ffffff', opacity: member ? 1 : 0 }"
            image="{{ member?.picture }}"
            [size]="$any(size)"
        ></p-avatar>
    `,
    styleUrls: ['./member-avatar.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberAvatarComponent implements OnChanges {
    @Input() size: Avatar['size'] = 'normal';
    @Input() member?: Pick<Member, 'picture' | 'id' | 'lastName' | 'firstName'> | null;
    @Input() backgroundColor = '#9c27b0';
    @Input() memberId?: string | null;

    private _userClient = inject(MemberClient);
    private _cdr = inject(ChangeDetectorRef);

    ngOnChanges(changes: Record<keyof MemberAvatarComponent, SimpleChange>): void {
        if (changes.member && this.member != null) {
            if (this.member.picture == null) {
                this._userClient.getById(this.member.id).subscribe(v => Object.assign(this.member ?? {}, v));
            }
        }
        if (changes.memberId && this.memberId && !this.member) {
            this._userClient.getById(this.memberId).subscribe(x => {
                this.member ??= x;
                this._cdr.markForCheck();
            });
        }
    }
}
