import { TreatmentFiles } from './treatment-file.model';
import { TreatmentReview } from './treatment-review.model';
import { TreatmentDesignRevisionJudgement } from './treatment-design-revision-judgement';
import { DesignPlatform } from './design-platform';

export interface TreatmentDesign {
    id: number;
    revision: number;
    treatmentId: string;
    dateAdded: string | Date;
    files: TreatmentFiles;
    deliveryStatus: DeliveryStatus;
    review?: TreatmentReview;
    isReviewRequested: boolean;
    canRequestReview: boolean;
    designerId: string;
    isUnread: boolean;
    revisionId?: string | null;
    designNote?: string | null;
    filesDelivered: boolean;
    filesDeliveredDate?: Date | string;
    revisionJudgement?: TreatmentDesignRevisionJudgement | null;
    designPlatform?: DesignPlatform | null;
    reviewSubmittedDate?: string | Date | null;
}

export type TreatmentDesigns = TreatmentDesign[];

export enum DeliveryStatus {
    none = 0,
    attaching = 1,
    attached = 2,
    errorAttach = 3,
}
