import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeContentPipe } from '../../core/pipe/safe-content.pipe';

@Component({
    selector: 'app-rich-text',
    standalone: true,
    imports: [CommonModule, SafeContentPipe],
    template: `
        <div *ngIf="text" [innerHTML]="text | safeContent : 'html'"></div>
    `,
    styleUrls: ['./rich-text.component.scss'],
})
export class RichTextComponent {
    @Input() text?: string | null;
}
