import { Component, Input, OnChanges, SimpleChange } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule } from 'primeng/editor';
import { TreatmentReviewerComponent } from '../treatment-reviewer';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RxHelper } from '../../core/utils/rxjxHelper';

export const createTreatmentReturnRequestEditForm = () => {
    return new FormGroup({
        id: new FormControl<number | undefined>({ value: undefined, disabled: false }, { nonNullable: true }),
        reason: new FormControl<string>(
            { value: '', disabled: false },
            { validators: [Validators.required], nonNullable: true }
        ),
        reviewerId: new FormControl<string | undefined>(
            { value: undefined, disabled: false },
            { validators: [Validators.required], nonNullable: true }
        ),
    });
};

@Component({
    selector: 'app-treatment-return-request-edit',
    standalone: true,
    imports: [CommonModule, EditorModule, TreatmentReviewerComponent, ReactiveFormsModule],
    templateUrl: './treatment-return-request-edit.component.html',
    styles: [
        `
            :host {
                height: 100%;
            }
        `,
    ],
    providers: [RxHelper],
})
export class TreatmentReturnRequestEditComponent implements OnChanges {
    @Input() isReviewerSelectDisabled = false;
    @Input() form = createTreatmentReturnRequestEditForm();

    ngOnChanges(changes: Record<keyof TreatmentReturnRequestEditComponent, SimpleChange>) {
        if (changes.isReviewerSelectDisabled) {
            if (this.isReviewerSelectDisabled) this.form.controls.reviewerId.disable();
            else this.form.controls.reviewerId.enable();
        }
    }
}
