import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeSelectComponent } from '../date-range-select';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DateRange } from '../date-range-select';
import { ButtonModule } from 'primeng/button';

@Component({
    selector: 'app-date-range-select-dialog',
    standalone: true,
    imports: [CommonModule, DateRangeSelectComponent, ButtonModule],
    template: `
        <div class="d-flex flex-column h-100">
            <div class="flex-grow-1">
                <app-date-range-select [(rangeDates)]="selectedRange"></app-date-range-select>
            </div>

            <div class="d-flex justify-content-end mt-4">
                <p-button
                    class="me-3"
                    styleClass="p-button-sm p-button-primary p-button-secondary p-button-outlined"
                    [outlined]="true"
                    label="取消"
                    (onClick)="onCancel()"
                />
                <p-button
                    styleClass="p-button-sm p-button-primary p-button-outlined"
                    [outlined]="true"
                    label="确定"
                    (onClick)="onConfirm()"
                />
            </div>
        </div>
    `,
    styles: [],
})
export class DateRangeSelectDialogComponent {
    selectedRange: DateRange = [new Date(), new Date()];
    protected readonly config = inject(DynamicDialogConfig, { optional: true });
    protected readonly ref = inject(DynamicDialogRef, { optional: true });

    protected onConfirm() {
        this.ref?.close(this.selectedRange);
    }

    protected onCancel() {
        this.ref?.close(false);
    }
}
