import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MembersSelectDialogComponent } from './members-select-dialog.component';
import { MembersSelectDialogConfig } from './members-select-dialog-config';
import { first, map } from 'rxjs';

@Injectable()
export class MemberSelectDialogService {
    constructor(private readonly _dialog: DialogService) {}

    open({
        data,
        onClose,
    }: {
        data: MembersSelectDialogConfig;
        onClose?: (result: any) => unknown | Promise<unknown>;
    }) {
        const ref = this._dialog.open(MembersSelectDialogComponent, {
            header: '邀请回答',
            width: '70vw',
            height: '80vh',
            data,
        });
        if (onClose != null) {
            ref.onClose
                .pipe(
                    first(),
                    map(r => r ?? { isSubmit: false })
                )
                .subscribe({
                    next: r => onClose(r),
                });
        }

        return ref;
    }
}
