import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { EditorModule } from 'primeng/editor';

export const createTreatmentReturnRequestReviewForm = () =>
    new FormGroup({
        treatmentReturnRequestId: new FormControl<number | undefined>(
            { value: undefined, disabled: false },
            { nonNullable: true, validators: [Validators.required] }
        ),
        body: new FormControl<string | undefined>({ value: undefined, disabled: false }, { nonNullable: true }),
        operation: new FormControl<number | undefined>(
            { value: undefined, disabled: false },
            { nonNullable: true, validators: [Validators.required] }
        ),
    });

@Component({
    selector: 'app-treatment-return-request-reply-edit',
    standalone: true,
    imports: [CommonModule, EditorModule, ReactiveFormsModule],
    templateUrl: './treatment-return-request-reply-edit.component.html',
    styleUrls: ['./treatment-return-request-reply-edit.component.scss'],
})
export class TreatmentReturnRequestReplyEditComponent {
    @Input() form = createTreatmentReturnRequestReviewForm();
}
