import { FormControl, FormGroup, Validators } from '@angular/forms';

export enum TreatmentQuestionReplyType {
    answer = 1,
    followUp = 2,
}

export interface TreatmentQuestionReply {
    id: number;
    content: string;
    type: TreatmentQuestionReplyType;
    treatmentId: string;
    authorId: string;
    dateAdded: string | Date;
    dateUpdated: string | Date;
}

export type TreatmentQuestionReplies = TreatmentQuestionReply[];

export const createTreatmentQuestionReplyForm = () =>
    new FormGroup({
        id: new FormControl({ disabled: true, value: 0 }),
        content: new FormControl({ disabled: false, value: '' }, [Validators.required]),
        type: new FormControl({ disabled: true, value: TreatmentQuestionReplyType.answer }),
    });

export type TreatmentQuestionReplyForm = ReturnType<typeof createTreatmentQuestionReplyForm>;
export type TreatmentQuestionReplyEditModel = TreatmentQuestionReplyForm['value'];
