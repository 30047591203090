import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { ReviewerSelectComponent } from '../reviewer-select/reviewer-select.component';
import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TreatmentClient } from '../../core/client/treatment-client';
import { TreatmentDesign } from '../../core/model/treatment-design.model';
import { Member } from '../../core/model/member.model';

@Component({
    selector: 'app-assign-reviewer',
    standalone: true,
    imports: [CommonModule, ButtonModule, ReviewerSelectComponent, FormsModule, ToastModule],
    template: `
        <div class="modal-content">
            <div class="modal-body">
                <app-reviewer-select (select)="this.selectedReviewer = $event" [(ngModel)]="selectedReviewerId" />
            </div>
            <div class="modal-footer mt-3">
                <p-button
                    (click)="dialogRef?.close('canceled')"
                    [text]="true"
                    styleClass="p-button-text p-button-secondary"
                    class="ms-3"
                    label="取消"
                />
                <p-button
                    [loading]="isSaving"
                    styleClass="p-button-text"
                    [text]="true"
                    class="ms-3"
                    label="保存"
                    (onClick)="onSave()"
                />
            </div>
        </div>

        <p-toast key="error-save" />
    `,
    styleUrls: ['./assign-reviewer.component.scss'],
})
export class AssignReviewerComponent implements OnInit {
    selectedReviewerId?: string | null;
    selectedReviewer?: Member | null;

    protected isSaving = false;

    protected readonly dialogRef = inject(DynamicDialogRef, { optional: true });
    protected readonly dialogConfig =
        inject<DynamicDialogConfig<{ design?: TreatmentDesign; reviewerId?: string }>>(DynamicDialogConfig);

    private readonly _messageService = inject(MessageService);
    private readonly _treatmentClient = inject(TreatmentClient);

    ngOnInit(): void {
        this.selectedReviewerId =
            this.dialogConfig.data?.reviewerId ?? this.dialogConfig.data?.design?.review?.reviewerId;
    }

    onSave() {
        const { selectedReviewerId } = this;
        if (!selectedReviewerId) {
            const key = 'error-save';
            this._messageService.clear(key);
            this._messageService.add({
                severity: 'warn',
                summary: '请选择质检员',
                detail: '请选择一个质检员后再保存',
                key: key,
            });
        } else {
            if (this.dialogConfig.data) {
                this.isSaving = true;

                const { design } = this.dialogConfig.data;
                if (design) {
                    this._treatmentClient.assignReviewer(design.treatmentId, design.id, selectedReviewerId).subscribe({
                        next: () => {
                            this.dialogRef?.close(this.selectedReviewer);
                            this._messageService.add({
                                severity: 'success',
                                summary: '保存成功',
                                detail: `质检员已设置为 ${this.selectedReviewer?.fullName}`,
                            });
                        },
                        complete: () => (this.isSaving = false),
                    });
                } else {
                    this.isSaving = false;
                    this.dialogRef?.close(this.selectedReviewer);
                }
            }
        }
    }
}
