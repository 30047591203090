import { Route, Routes } from '@angular/router';

const treatmentRoute: Route = {
    loadChildren: () => import('./pages/treatment/treatment.routing').then(m => m.TreatmentRoutes),
};

export const AppRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        ...treatmentRoute,
    },
    {
        path: 'treatment',
        ...treatmentRoute,
    },
    {
        path: '403.html',
        loadComponent: () => import('./pages/common').then(x => x.ForbiddenComponent),
    },
    {
        path: 'admin/members',
        loadChildren: () => import('./pages/member').then(x => x.MemberRoutes),
    },
    {
        path: 'data-insights',
        loadChildren: () => import('./pages/data-insights').then(x => x.DataInsightsRoutes),
    },
];
