<div [class.disabled]="form.disabled" class="h-100 d-flex flex-column overflow-hidden">
    <div class="overflow-hidden flex-grow-1">
        <p-editor
            placeholder="请在此输入退单理由..."
            [formControl]="form.controls.reason"
            [disabled]="form.controls.reason.disabled"
        />
    </div>
    <div class="mt-3">
        <div class="d-flex align-items-center">
            <div><span>质检员:&nbsp;</span></div>
            <div>
                <app-treatment-reviewer
                    (onReviewerSelected)="form.controls.reviewerId.setValue($event.id)"
                    [reviewerId]="form.controls.reviewerId.value"
                    [isDisable]="form.controls.reviewerId.disabled"
                />
            </div>
        </div>
    </div>
</div>
