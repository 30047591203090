import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
    selector: 'app-loading',
    standalone: true,
    imports: [CommonModule, ProgressSpinnerModule],
    template: `
        <p-progressSpinner
            [style]="{ width: size, height: size }"
            [style.width]="size"
            [style.height]="size"
            class="d-inline-block"
            strokeWidth="3"
            fill="var(--surface-ground)"
            animationDuration=".5s"
        />
    `,
    styles: [],
    host: {
        class: 'd-inline-block',
        '[style.width]': 'size',
        '[style.height]': 'size',
    },
})
export class LoadingComponent {
    @Input() size = '40px';
}
