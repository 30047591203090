import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface DoctorPreference {
    id: string;
    doctorId: string;
    designPreference: string;
    translation: string;
    createdDate: Date | string;
    modifiedDate: Date | string;
    createdBy?: string | null;
}

export const createDoctorPreferenceEditForm = () =>
    new FormGroup({
        translation: new FormControl<string | null>({ disabled: false, value: null }, Validators.required),
        designPreference: new FormControl<string | null>({ value: null, disabled: false }),
    });

export type DoctorPreferenceEditForm = ReturnType<typeof createDoctorPreferenceEditForm>;
export type DoctorPreferenceEditModel = DoctorPreferenceEditForm['value'];
